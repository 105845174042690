import { useDispatch, useSelector } from 'react-redux';
import { selectors as RegisteredUsers, actions as AnalyticsSessionsActions } from '../store/slices/passCodeUsers'
import { useEffect, useState } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import UsersReportDownloader from './UsersExcelReport'

import {
  ButtonToolbar, ButtonGroup, Button, Modal, ModalBody, ModalFooter, ModalHeader,
  Card, Alert, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Container, Row, Col, Form, FormGroup, Label, Input, FormText
} from "reactstrap";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { selectors as UsersSelectors, actions as UsersActions } from '../store/slices/users'
import {BsFillCheckCircleFill} from "react-icons/bs";
import {MdCancel} from "react-icons/md";
import { IconContext } from "react-icons";

export const RegisteredUsersViewer = (props: any) => {
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    const [usersRows, setUsersRows] = useState([])
    const users =  useSelector(UsersSelectors.getRegisteredUsers);
    const lastUserProfile = useSelector(UsersSelectors.getLastUserProfile);
    const userProfiles = useSelector(UsersSelectors.getUserProfiles);
    const [currentSelectedRows, setCurrentSelectedRows] = useState({}) as any;

    /*
    useEffect(()=>{
      if (users!=null && userProfiles!=null)
      console.log(`TOTALE USERS: ${users.length} PROFILES: ${Object.keys(userProfiles).length}`);
    },[lastUserProfile, users,userProfiles])
    */
   
    useEffect(()=>{
       
        if (users!=null)
        setUsersRows(Object.values(users))
        else 
         setUsersRows([]);

         //console.log("UsersV users:", users);
         //console.log("UsersV usersRows:", usersRows);
    },[users])

    const paginationOptions = {
        sizePerPageList: [{
          text: `5 ${t("utenti")}`, value: 5
        }, {
          text: `10 ${t("utenti")}`, value: 10
        },
        {
            text: `20 ${t("utenti")}`, value: 20
          },
          {
            text: `50 ${t("utenti")}`, value: 50
          },
          {
            text: `100 ${t("utenti")}`, value: 100
          },
        {
          text: t('Tutti gli utenti'), value: (usersRows ? usersRows.length : 100)
        }
        ], pageStartIndex: 1
      }

      const dateFormatter = (cell: any, row: any) => {
        return moment(cell).format('DD/MM/YYYY - HH:mm');
      }
      const booleanFormatter = (cell: any, row: any) => {
        //console.log("Passo la riga:", row);
        //console.log("Passo la cella:", cell);
        return (
            (cell==true) ?
            <IconContext.Provider value={{ color: "green", size:"20", className: "global-class-name" }}>
                <BsFillCheckCircleFill/>
            </IconContext.Provider>
            :

            <IconContext.Provider value={{ color: "red", size:"24", className: "global-class-name" }}>
            <MdCancel/>
        </IconContext.Provider>
               
        )
      }

      const userStateFormatter = (cell: any, row: any) => {
        //console.log("Passo la riga:", row);
        //console.log("Passo la cella:", cell);
        return (
            (row.enabled==true && row.email_verified=="true") ?
            <IconContext.Provider value={{ color: "green", size:"20",  className: "global-class-name" }}>
                <BsFillCheckCircleFill/>
            </IconContext.Provider>
            :

            <IconContext.Provider value={{ color: "red", size:"24", className: "global-class-name" }}>
            <MdCancel/>
        </IconContext.Provider>
               
        )
      }

    
      const usersColumns = [
        {
          dataField: 'id',
          text: t("id"),
          hidden: false,
          sort: true
        },
        {
            dataField: 'family_name',
            text: t("Surname"),
           
            sort: true
          },
          {
            dataField: 'given_name',
            text: t("Name"),
            sort: true
          },
          {
            dataField: 'type',
            text: t("Tipo"),
            sort: true
          },
          {
            dataField: 'email',
            text: t("Email"),
            sort: true
          },
          {
            dataField: 'school',
            text: t("Scuola"),
            sort: true
          },
          {
            dataField: 'schoolType',
            text: t("Tipo di scuola"),
            sort: true
          },
          {
            dataField: 'schoolLevel',
            text: t("Grado"),
            sort: true
          },
          {
            dataField: 'schoolMatter',
            text: t("Materia"),
            headerStyle: () => {
                return { width: '10%' };
              },
            sort: true
          },
          {
            dataField: 'create_date',
            text: t("Data di creazione"),
            formatter: dateFormatter,
            sort: true
          },
          {
            dataField: 'enabled',
            text: t("Attivo"),
            formatter: userStateFormatter,
            sort: true,
            headerStyle: () => {
                return { width: '5%' };
              },
            sortFunc: (a:any, b:any, order:any, dataField:any, rowA:any, rowB:any) => {
                const rowAval = rowA.enabled==true && rowA.email_verified=="true"
                const rowBval = rowB.enabled==true && rowB.email_verified=="true"
                if (order === 'asc') 
                { 
                    return (rowAval && !rowBval ? 1 : -1)
                }
                else return (rowAval && !rowBval ? -1 : 1)
              }
           
          },
          {
            dataField: 'newsletter_accepted',
            headerStyle: () => {
                return { width: '5%' };
              },
            text: t("Newsletter"),
            formatter: booleanFormatter,
            sort: true
          },
          {
            dataField: 'contest2024',
            headerStyle: () => {
                return { width: '5%' };
              },
            text: t("Contest 2024"),
            formatter: booleanFormatter,
            sort: true
          },
    ]

    const convertArrayToObject = (array: any, key: any) => {
        const initialValue = {}
        return array.reduce((obj: any, item: any) => {
          return { ...obj, [item[key]]: item, }
        }, initialValue);
      }
    

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
          //console.log("Select row", row);
    
          setCurrentSelectedRows((prevState: any) => ({
            ...prevState,
            [row.ulid]: (isSelect ? row : null)
          }));
    
          //console.log("(select row) Current Selected rows", currentSelectedRows);
        },
        onSelectAll: (isSelect: any, rows: any, e: any) => {
          //console.log(isSelect);
          //console.log(rows);
          let newSelectedRows = isSelect ? convertArrayToObject(rows, "ulid") : {}
          setCurrentSelectedRows(newSelectedRows);
          //console.log("(select all) Current Selected rows", currentSelectedRows);
        }
      } as any;

    return (
        <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
        <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff",
         paddingBottom: 0, color: 'white' }}>
          <CardTitle tag="h5">{t("Elenco degli utenti registrati")}{` (${t("Totale")}:${users ? users.length:0}) `}
           {users != null && users.length>0 &&
           <UsersReportDownloader users={users} onlyAccepted />
           }
          
          </CardTitle>
          </CardHeader>
          <CardBody>
          {
          users != null && Object.keys(users).length>0 ?
            <BootstrapTable bootstrap4 keyField='id' data={usersRows || []}
              columns={usersColumns} selectRow={selectRow}
              pagination={paginationFactory(paginationOptions)} />
            :
            <Alert>{t("Caricamento utenti in corso...")}</Alert>
        }

          </CardBody>
          </Card>
    )
}