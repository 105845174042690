import React, { useState } from 'react';
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import { Footer } from '../../header'
import ReactTooltip from "react-tooltip";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors as AppointmentsSelector, actions as AppointmentActions,
  Appointmentstate,  getAppointmentById
} from '../../store/slices/appointments'
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { useTranslation } from 'react-i18next';
import { selectors as CatalogSelector } from '../../store/slices/catalog'
import { selectors as ProfileSelectors } from '../../store/slices/profile'
import moment from 'moment';
import {
  Alert, Card, CardBody, CardHeader, CardFooter, CardTitle, Button,
  Modal, ModalHeader,ModalBody, ModalFooter
} from 'reactstrap';
import { ActivityButton } from '../ActivityButton'
import QRCode from "react-qr-code";
import {  isAppointmentBookedByUser } from "../Calendar/appointmentsUtils"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faVideo, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { push } from 'connected-react-router';
import { CenteredText } from '../../styles/styles';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { SYNCHRONOUS_SESSION_TYPE } from '../../store/sagas/remotesessions';
import { FaTrashAlt } from "react-icons/fa";
import { GiAirplaneDeparture } from "react-icons/gi";

export const SyncSessionsDashboard = () => {

  const dispatch = useDispatch();
  const userAttributes = useSelector(ProfileSelectors.getProfile);
  const appointments = useSelector(AppointmentsSelector.getAppointments);
  const experiments = useSelector(CatalogSelector.getExperiments);
  const isTeacher = useSelector(AuthSelectors.isTeacher);
  const isStudent = useSelector(AuthSelectors.isStudent);
  const [currentQrcodeUrl, setCurrentQrcodeUrl] = useState(null) as any;
  const { t } = useTranslation('frontend', { useSuspense: false });
  const [appointmentToUnbook, setAppointmentToUnbook] = useState(null);
  const [isUnbookingPending, setUnbookingPending] = useState(false);
  
  const closeUnbookAppointmentModal = () => {
    setAppointmentToUnbook(null);
    setUnbookingPending(false);
  }

  const unbookAppointment = (appointmentId:any) => {
    const appointment = getAppointmentById(appointments as any, appointmentId);
    if (appointment != null) {
      //console.log("SAGA sto passando da editor app:", appointment);
      dispatch(AppointmentActions.willUnbookAppointment(appointment));
      setUnbookingPending(true);
    };
  }
  
  React.useEffect(() => {
    dispatch(AppointmentActions.willLoadAppointments(
      { "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z" , "type" : SYNCHRONOUS_SESSION_TYPE }
    ));

    //console.log("dashboard: called willLoadAppointments");
  }, []);

  React.useEffect(() => {
    //console.log("APPOINTMENTS UPDATED");
    setUnbookingPending(false);
  }, [appointments])


  React.useEffect(() => {
    console.log("UNBOOKING_PENDING:", isUnbookingPending);
    if (!isUnbookingPending) setAppointmentToUnbook(null);
  }, [isUnbookingPending])

  React.useEffect(() => {
   // console.log("APPOINTMENTS UPDATED");
    setUnbookingPending(false);
  }, [appointments])


  React.useEffect(() => {
    console.log("UNBOOKING_PENDING:", isUnbookingPending);
    if (!isUnbookingPending) setAppointmentToUnbook(null);
  }, [isUnbookingPending])


  //console.log("dashboard appointments:", appointments);

  const titleFormatter = (cell: any, row: any) => {
    return (<div style={{display:"flex" , flexDirection:"column"}}>
    
      <span>{cell}</span>
      {row["contest2024"] && 
      <div style={{display:"flex", justifyContent: 'right'}}>
          <GiAirplaneDeparture data-tip={t("Contest 2024")}  
              size={25}  
              style={{ margin: "5px" , paddingTop:"0px"}} color={"#007bff"}
             >
             </GiAirplaneDeparture>
      </div>
      }
    </div>)
    return moment(cell).format('DD/MM/YYYY - HH:mm');
  }

  const startStopFormatter = (cell: any, row: any) => {
    return moment(cell).format('DD/MM/YYYY - HH:mm');
  }

  const experimentDetailsLinkFormatter = (cell: any, row: any) => {
    //console.log("Passo la riga:", row);
    //console.log("Passo la cella:", cell);
    return (

      <a href="#"><FontAwesomeIcon icon={faChevronCircleRight as IconLookup}
        onClick={() => { dispatch(push(`/experiment/${row.experiment_id}`)); }}
        style={{ alignSelf: "center", fontSize: 18 }} color="#007bff" /></a>
    )
  }

  const meetingFormatter = (cell: any, row: any) => {
    return <a onClick={() => 
       {
      const zoomUrl = `${row.joinUrl}`
      const win = window.open(zoomUrl, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=640');
      win!.focus();
      }} href="#">{row.joinUrl}</a>
  }

  const qrcodeFormatter = (cell: any, row: any) => {
    return <a href="#"><FontAwesomeIcon icon={faQrcode as IconLookup}
      onClick={(ev) => {
        setCurrentQrcodeUrl({"title" : row.title,  "url" : row.qrcode_questions_url});
      }}
      style={{ fontSize: 18 }} color="#007bff"
    /> </a>

  }

  const unbookFormatter = (cell: any, row: any) => {
    return(
      <> 
      <FaTrashAlt data-tip={t("Disdici")} data-for="tip-unbook"
             cursor="pointer"
             size={18}  
             style={{ margin: "5px" , paddingTop:"0px"}} color={"red"}
             onClick={(ev:any) => {
              console.log("record value:", row);
              setAppointmentToUnbook(row.id)
              
            }}
            >
            </FaTrashAlt>
            <ReactTooltip  id="tip-unbook"/>
            </>
    )
  }


  const ptzPanelLinkFormatter = (cell: any, row: any) => {
    //console.log("Cella:", cell);
    return <a href="#"><FontAwesomeIcon icon={faVideo as IconLookup}

      onClick={(ev) => {
        //console.log(`Click for PTZ session:${row.id} organization:${row.organization}`);
        const cameraUrl = `/live/${row.organization}/${row.id}/${row.experiment_id}`
        const win = window.open(cameraUrl, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=500,height=250');
        //const win = window.open('/live/123456','Controllo PTZ', "resizable,height=600,width=670");
        win!.focus();
      }}
      style={{ fontSize: 18 }} color="#007bff"
    /> </a>

  }


  const columns = (next: boolean) => [
    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
      dataField: 'contest2024',
      text: 'contest2024',
      hidden: true
    },
    {
      dataField: 'organization',
      text: 'organization',
      hidden: true
    },
    {
      dataField: 'experiment_id',
      text: 'experiment_id',
      hidden: true
    },
    {
      dataField: 'startDate',
      text: t('Inizio'),
      headerStyle: () => {
        return { width: '12%' };
      },
      formatter: startStopFormatter,
      sort: true,
      sortFunc: (a:any, b:string, order:string) => {
        const isBefore = (order=="asc") && moment(a).isBefore(moment(b)) || (order!="asc" && moment(b).isBefore(moment(a)))
        return (isBefore ? -1 : 1)
      },
    },
    {
      dataField: 'stopDate',
      text: t('Fine'),
      headerStyle: () => {
        return { width: '12%' };
      },
      formatter: startStopFormatter,
      sort: true,
      sortFunc: (a:any, b:string, order:string) => {
        const isBefore = (order=="asc") && moment(a).isBefore(moment(b)) || (order!="asc" && moment(b).isBefore(moment(a)))
        return (isBefore ? -1 : 1)
      },
    },

    {
      dataField: 'title',
      text: t('titolo'),
      sort: true,
      formatter: titleFormatter
    },

    {
      dataField: 'lab',
      text: t('Laboratorio'),
      sort: true,
    },

    {
      dataField: 'joinUrl',
      text: 'Meeting',
      headerAlign: "center",
      formatter: meetingFormatter,
      sort: false,
      hidden: !next
    },
    {
      dataField: 'note',
      text: t('Note'),
      headerAlign: "center",
      sort: false,
      hidden: false
    },
    {
      dataField: 'qrcode_questions_url',
      text: t('QR Code studenti'),
      formatter: qrcodeFormatter,
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: '8%' };
      },
      sort: false,
      hidden: !next
    },
    {
      dataField: 'liveLink',
      text: next ? t('Controllo Camera') : t('Dettagli'),
      formatter: (next ? ptzPanelLinkFormatter : experimentDetailsLinkFormatter),
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'unbook',
      text: t(''),
      formatter: unbookFormatter,
      headerStyle: () => {
        return { width: '4%' };
      },
      headerAlign: 'center',
      hidden: !next
    },

  ];

  const paginationOptions = {
    sizePerPageList: [{
      text: '3th', value: 3
    }
    ], pageStartIndex: 1
  }

  //console.log("dashboard appointments:", appointments);

  const nextAppointments = appointments.filter((app: any, i: number) => {
   
    return (
      // filtro nel caso siano state omessi degli esperimenti dal catalogo corrente
      experiments != null && experiments[app["title"]] != null &&
      isAppointmentBookedByUser(app,userAttributes) &&
      moment(app["stopDate"]).isAfter(moment.now()))
  }).map((app: any, i: number) => {
    //console.log("Attendee prenotante", app["attendees"].find((att:any)=>{ return att["user"]==userAttributes.sub})?.contest2024);
    return {
      "id": app["id"],
      "startDate": `${app["startDate"]}`,
      "stopDate": `${app["stopDate"]}`,
      "title": (experiments[app["title"]] as any)["titolo"],
      "experiment_id": app["title"],
      "organization": app["organization"],
      "lab": app["lab"],
      "note" : app["note"],
      "qrcode_questions_url": (experiments[app["title"]] as any)["qrcode_questions_url"],
      "joinUrl": app["joinUrl"],
      "contest2024" : app["attendees"].find((att:any)=>{ return att["user"]==userAttributes.sub})?.contest2024==true
    }
  });

  const lastAppointments = appointments.filter((app: any, i: number) => {
    return experiments != null && experiments[app["title"]] != null &&
      isAppointmentBookedByUser(app,userAttributes) &&
      !moment(app["stopDate"]).isAfter(moment.now())
  }).filter((app: any, i: number) => { return (experiments[app["title"]] as any) != null }).map((app: any, i: number) => {
    //console.log("Last app id:", app["id"]);
    return {
      "id": app["id"], "startDate": `${app["startDate"]}`, "stopDate": `${app["stopDate"]}`,
      "experiment_id": app["title"],
      "organization": app["organization"],
      "title": (experiments[app["title"]] as any)["titolo"],
      "lab": app["lab"],
      "note" : app["note"],
      "joinUrl": app["joinUrl"],
      "contest2024" : app["attendees"].find((att:any)=>{ return att["user"]==userAttributes.sub})?.contest2024==true
    }
  });

  //const products = [{name:"stefano"}, {name:"marco"}];
  //console.log("dashboard nextAppointments:", nextAppointments);
  //console.log("dashboard lastappointments:", lastAppointments);
  return (
    <>
         <Modal isOpen={appointmentToUnbook!=null || isUnbookingPending}>
        <ModalHeader>{t("Cancellazione della prenotazione")}</ModalHeader>
        <ModalBody>{
        t("Confermare la cancellazione dell'appuntamento?")}</ModalBody>
        <ModalFooter>
          <ActivityButton color="primary" name="unbookAppointment" 
          onClick={(ev:any) =>unbookAppointment(appointmentToUnbook)}>{t("Si")}</ActivityButton>{' '}
          <Button color="secondary" onClick={closeUnbookAppointmentModal}>{t("No")}</Button>
        </ModalFooter>
  </Modal>
    
      {
        currentQrcodeUrl && <Modal isOpen={currentQrcodeUrl != null} >
          <ModalBody style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Card className="mb-4" style={{ padding:"10px", borderColor: "#007bff" }}>
              <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                <CardTitle tag="h5">
                {currentQrcodeUrl["title"]}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <CenteredText>
                <QRCode style={{marginTop:"20px"}} value={`${currentQrcodeUrl["url"]}`} size={256} />
              </CenteredText>
              </CardBody>
              <CardFooter>
                <Alert>{t("Inquadra il qrcode col tuo cellulare e inoltra le tue domande ai tutor durante la sessione sincrona!")}</Alert>
              </CardFooter>
            </Card>
            
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { setCurrentQrcodeUrl(null) }}>{t("Chiudi")}</Button>
          </ModalFooter>
        </Modal>
      }
     
        <Card className="m-4" style={{ 
          marginTop:"-10px",
          borderColor: "#007bff"
        }}>
          <CardHeader data-tip={t("Area tematica")} style={{
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            paddingBottom: 0,
            color: 'white'

          }}>
            <CardTitle tag="h5">{t("Esperimenti Live")}</CardTitle>
          </CardHeader>
          <CardBody>

            {
              nextAppointments.length >0 ?
              (
          <BootstrapTable bootstrap4 keyField='id' data={ 
            nextAppointments
           } 
          columns={ columns(true) } pagination={ paginationFactory(paginationOptions)}/>)
          :
          <Alert color="info" className="text-center">
          {t("alertNoBookedExperiments")}
         </Alert>
            }
          </CardBody>
        </Card>

        <Card className="m-4" style={{
          borderColor: "#007bff"
        }}>
          <CardHeader data-tip={t("Area tematica")} style={{
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            paddingBottom: 0,
            color: 'white'

          }}>
            <CardTitle tag="h5">{t("Ultime Attività")}</CardTitle>
          </CardHeader>
          <CardBody>
            {
              lastAppointments.length > 0 ? 
          <BootstrapTable bootstrap4 keyField='id' data={ 
            lastAppointments
           } 
          columns={ columns(false) } 
          defaultSorted={[{dataField:"startDate", order:"desc"}]}
          pagination={ paginationFactory(paginationOptions)}/>
          :
          <Alert color="info" className="text-center">
          {t("alertNoPastExperiments")}
         </Alert>

            }
          </CardBody>
          <CardFooter>
           <Footer/>
          </CardFooter>
        </Card>
    </>
  )
}