import { useEffect, useState, useRef } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {selectors as UserSelector} from '../../store/slices/users'
import { Contest2024TraceAnalyzer } from "./Contest2024TraceAnalyzer";

export const ContestStudentsAnalyzer = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    const [studentSessionRows, setStudentSessionRows] = useState([])
    const users = useSelector(UserSelector.getUsers2)

    useEffect(()=>{
        if (users)
            {
                console.log("schoolData:" , props.schoolData)
                const studentsData = props.schema[ props.schoolData["id"]][props.timelineData["id"]];
                
                console.log("studentsData:" , studentsData)
                setStudentSessionRows(
                    Object.keys(studentsData).map((userId)=>{
                        console.log("UserId:", userId, users[userId]);
                        const sessions = studentsData[userId]
                        const studentRecord ={...users[userId] , sessions }
                        return studentRecord}))}
    }, [users])


    useEffect(()=>{
        console.log("studentSessionRows:", studentSessionRows)
    }, [studentSessionRows])

    const paginationOptions = {
        sizePerPageList: [{
            text: `5 ${t("sessioni")}`, value: 5
        }, {
            text: `10 ${t("sessioni")}`, value: 10
        },
        {
            text: t('Tutte le sessioni'), value: (studentSessionRows ? studentSessionRows.length : 100)
        }
        ], pageStartIndex: 1
    }

    const sessionOverviewFormatter = (cell,row) => {
        console.log("SCHEMA::", props.schoolData)
        return ( <Contest2024TraceAnalyzer
            timelineInfo={props.schoolData["tracesOverview"][props.timelineData["id"]]["students"][row.id]}/>)
    }
    const numSessionsFormatter = (cell, row) => {
        
        return `${row["sessions"].length}`
      }

    const fullnameFormatter = (cell, row) => {
        
        return `${row["family_name"]} ${row["given_name"]}`
      }

    const studentSessionColumns = [
        {
            dataField: 'id',
            text: t("id"),
            sort: true,
            hidden: true
        },
        {
            dataField: 'full_name',
            text: t("Student"),
            formatter: fullnameFormatter,
            sort: true 
        } ,
        {
            dataField: 'numSessions',
            text: t("Totale sessioni"),
            formatter: numSessionsFormatter,
            hidden: true,
            sort: true 
        } ,
        {
            dataField: 'overview',
            text: t("Riepilogo"),
            formatter: sessionOverviewFormatter,
            sort: true 
        } 
    ]


    return <BootstrapTable bootstrap4
        keyField='id' data={studentSessionRows || []}
        columns={studentSessionColumns}
        pagination={paginationFactory(paginationOptions)} />
}