// traceAnalyzerUtils.js

export const analyzeTrace = (trace) => {
    if (!trace) return null;

    let intervalliUniti = [];

    const buildTimelineSegments = (duration) => {
        if (duration === 0 || duration === null) return null;
        return calculateSegments(duration, intervalliUniti);
    };

    const calculateSegments = (duration, intervals) => {
        intervals.sort((a, b) => a.from - b.from);
        const segments = [];
        let previousEnd = 0;

        intervals.forEach(interval => {
            if (interval.from > previousEnd) {
                segments.push({ percentage: ((interval.from - previousEnd) / duration) * 100, color: 'danger' });
            }
            segments.push({ percentage: ((interval.to - interval.from) / duration) * 100, color: 'success' });
            previousEnd = interval.to;
        });

        if (previousEnd < duration) {
            segments.push({ percentage: ((duration - previousEnd) / duration) * 100, color: 'danger' });
        }

        return segments;
    };

    const unisciIntervalli = (intervalli) => {
        if (intervalli.length === 0) return [];

        intervalli.sort((a, b) => a.from - b.from);

        const intervalliUnitiLocal = [intervalli[0]];
        intervalliUniti = intervalliUnitiLocal;

        for (let i = 1; i < intervalli.length; i++) {
            const ultimoIntervallo = intervalliUnitiLocal[intervalliUnitiLocal.length - 1];
            const intervalloCorrente = intervalli[i];

            if (intervalloCorrente.from <= ultimoIntervallo.to) {
                ultimoIntervallo.to = Math.max(ultimoIntervallo.to, intervalloCorrente.to);
            } else {
                intervalliUnitiLocal.push(intervalloCorrente);
            }
        }

        return intervalliUnitiLocal;
    };

    const getCoperturaVideo = (videoLength) => {
        const actions = trace.actions;
        const playChangeEvents = actions.filter((action) => {
            return action["type"] === 'PLAY_CHANGE';
        });

        const intervalliVisti = [];

        try {
            for (let i = 0; i < playChangeEvents.length - 1; i += 2) {
                if (playChangeEvents[i]["payload"]["playing"] === true && playChangeEvents[i + 1]["payload"]["playing"] === false) {
                    intervalliVisti.push({
                        from: playChangeEvents[i]["payload"]["absVideoPositionInSecs"],
                        to: playChangeEvents[i + 1]["payload"]["absVideoPositionInSecs"]
                    });
                }
            }

            const intervalliUnitiLocal = unisciIntervalli(intervalliVisti);

            const secondiVisti = intervalliUnitiLocal.reduce((totale, intervallo) => {
                return totale + (intervallo.to - intervallo.from);
            }, 0);

            const percentualeVista = (secondiVisti / videoLength) * 100;

            return { "coperturaVideoPercentuale" : percentualeVista,
                     "secondiVisti" : secondiVisti,
                     "info" :`${secondiVisti.toFixed(2)} secs. (${percentualeVista.toFixed(2)}%)`};

        } catch (ex) {
            console.error("Impossibile calcolare la copertura video:", ex);
            return "n.a";
        }
    };

    const processTrace = () => {
        const actions = trace.actions;

        if (actions != null) {
            const info = actions.find((action) => {
                return action["type"] === 'TIMELINE_LITE_OPEN';
            });

            const totaleAllegati = info["payload"]["attachmentItems"]?.length || 0;
            const durataTotaleVideo = info["payload"]["videoItems"].reduce(
                (acc, video) => acc + video["duration"], 0
            );

            const attachmentClicks = actions.filter((action) => {
                return action["type"] === 'CLICK_ATTACHMENT';
            });

            const totaleClickAllegati = attachmentClicks?.length || 0;
            const resocontoClickAllegati = attachmentClicks.reduce((resoconto, attachment) => {
                const id = attachment.payload.item.id;
                resoconto[id] = (resoconto[id] || 0) + 1;
                return resoconto;
            }, {});

            const numeroAllegatiAperti = Object.keys(resocontoClickAllegati).length;
            const percentualeAllegatiAperti = 100 * (numeroAllegatiAperti / totaleAllegati);
            const coperturaVideo = getCoperturaVideo(durataTotaleVideo);
            const segments = buildTimelineSegments(durataTotaleVideo);

            return {
                id: info["payload"]["timelineId"],
                durata: durataTotaleVideo,
                numeroVideo: info["payload"]["videoItems"].length,
                numeroAllegati: totaleAllegati,
                numeroAllegatiAperti: numeroAllegatiAperti,
                percentualeAllegatiAperti: percentualeAllegatiAperti,
                clickAllegati: totaleClickAllegati,
                coperturaVideo: coperturaVideo,
                videoIntervals: segments
            };
        }

        return null;
    };

    // Calcola i dati della traccia e restituiscili
    return processTrace();
};
