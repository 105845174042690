import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import events from '../../demo_events'
import moment from 'moment'
import { INTERACTIVE_SESSION_TYPE } from "../sagas/remotesessions";

const getAppointmentIndexById = (appointments: any, id: any) =>
{
  if (id==null) return -1;

 // console.log(`Ricerco ${id} su:`, appointments);
  return appointments.findIndex( (appEl: { id: any; }) => appEl.id===id);
}

export enum Appointmentstate {NOT_FOUND="not found", 
                              CREATE="create",
                              BOOKED="booked", 
                              PARTIALLY_BOOKED="partially_booked",
                              UNBOOKED="unbooked"};


  export const isNoLongerAvailable = (appointment:any) => 
      {
        if (appointment==null) return true;
       // else return false; // only for debugging 

       const isInteractiveSession = appointment.type?.startsWith(INTERACTIVE_SESSION_TYPE);
    
        const numero_giorni_preavviso =(isInteractiveSession ? 0 : 2) // da 3 a 7 in produzione per le sincrone!!!

        ////console.log("Giorni di preavviso:", moment(appointment.startDate).diff(moment())/(24*3600000));
        //https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/07-difference/
        return (appointment.state!=Appointmentstate.BOOKED &&
          //(moment(appointment.startDate).diff(moment())/(24*3600000) < numero_giorni_preavviso))
          (moment(appointment.startDate).startOf("day").diff(moment().startOf("day"),"days",true) <= numero_giorni_preavviso))
      }                              

export const getAppointmentById = (appointments: any, id: any) =>
{
 // console.log(`Ricerco ${id} su:`, appointments);
  const index = getAppointmentIndexById(appointments,id);
  if (index<0) return null;
  else return appointments[index];
}


export const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState: {
      events : [],
      interactiveSessions : [],
      bookedEvents: [],
      sessionResources: {},
      eventsLoaded : false,
      isLoadingDirectorDashboard: false,
      directors : [], // regie delle sessioni sincrone
      sessionDirectors: {}, // dizionario delle regie per ciascuna sessione remota
      interactiveDevices : {} // dizionario dei dispositivi remotizzati
    }
  ,
    reducers: {
      willLoadAppointments: (state, action: PayloadAction<any>) => state,
      willLoadAppointmentsAndDirectors: (state, action: PayloadAction<any>) => state,
      willCreateAppointment: (state, action: PayloadAction<any>) => state,
      willUpdateAppointment: (state, action: PayloadAction<any>) => state,
      willDeleteAppointment: (state, action: PayloadAction<any>) => state,
      willConfirmAppointment: (state, action: PayloadAction<any>) => state,
      willBookAppointment: (state, action: PayloadAction<any>) => state,
      willUnbookAppointment: (state, action: PayloadAction<any>) => state,
      willUnbookAppointmentByEditor: (state, action: PayloadAction<any>) => state,
      willLoadResourcesForSession: (state, action: PayloadAction<any>) => state,
      willLoadDirectors: (state, action: PayloadAction<any>) => state,
      willLoadInteractiveDevices: (state, action: PayloadAction<any>) => state,
      willGetSessionDirectors: (state, action: PayloadAction<any>) => state,
      willGetUserProfileAsDirector: (state, action: PayloadAction<any>) => state,
      willUpdateSessionDirectors: (state, action: PayloadAction<any>) => state,
      willPutGrantedAttendeesInRemoteSession: (state, action: PayloadAction<any>) => state,
      willPutHomeworkUrlInRemoteSession: (state, action: PayloadAction<any>) => state,
      willSyncCalendars: (state, action: PayloadAction<any>) => state,
      willStartStopEC2byInteractiveDeviceId: (state, action: PayloadAction<any>) => state,
      setLoadingDirectorDashboard: (state: any, action: PayloadAction<any>) =>
      {
        //console.log("setLoadingDirectorDashboard to:", action.payload);
        state.isLoadingDirectorDashboard = action.payload;
      },


      setAppointmentsLoaded: (state: any, action: PayloadAction<any>) =>
      {
        state.eventsLoaded = action.payload;
      },

      setDirectors: (state: any, action: PayloadAction<any>) =>
      {
        state.directors = action.payload;
      },

      setInteractiveDevices: (state: any, action: PayloadAction<any>) =>
      {
        state.interactiveDevices = action.payload;
      },


      setSessionDirectors: (state: any, action: PayloadAction<any>) =>
      {
        const {session, directors} = action.payload;
        state.sessionDirectors[session] = directors;
      },

      setupAllSessionDirectors: (state: any, action: PayloadAction<any>) =>
      {
        state.sessionDirectors = action.payload;
      },

      cancelAppointment: (state: any, action: PayloadAction<any>) => 
      {
        
        const index = getAppointmentIndexById(state.events, action.payload.id);
        //console.log(`cancelAppointment called su app ->${action.payload.id} index:${index}`, action.payload);
        if (index>=0)
         {
           //console.log(`Aggiorno lo stato di appointment n.${action.payload.id}`);
           (state.events[index] as any).state = Appointmentstate.UNBOOKED;
           (state.events[index] as any).attendee = "";
          }
      },

      editAppointment: (state: any, action: PayloadAction<any>) =>  
        {
          const appointment = action.payload;
          const isInteractiveSession = appointment.type?.startsWith(INTERACTIVE_SESSION_TYPE);
          const appointmentsOrISessions = isInteractiveSession ?
          state.interactiveSessions : state.events;

          //console.log(`Sto cercando l'appuntamento di tipo ${appointment.type} con id:${appointment.id}`);
    
          let index = getAppointmentIndexById(appointmentsOrISessions,appointment.id);
          if (index>=0)
          {
            appointmentsOrISessions[index] = appointment;
            //console.log(`Ho trovato l'appuntamento con id=${appointment.id}`);
          }
          
          else {
            //console.log("Appuntamento nuovo da creare", action.payload);

            if (appointment.type?.startsWith(INTERACTIVE_SESSION_TYPE))
            {
              state.interactiveSessions = [
                ...state.interactiveSessions,
                action.payload
              ];
            }
            else{
              state.events = [
                ...state.events,
                action.payload
              ];

            }
          }
         
        } , 

        didBookedAppointmentsloaded:(state: any, action: PayloadAction<any>) =>  
        {
          
          state.bookedEvents = action.payload;
          //console.log("didBookedAppointmentsloaded:", state.bookedEvents);
        },

        didAppointmentsloaded:(state: any, action: PayloadAction<any>) =>  
        {
          state.events = action.payload;
          state.eventsLoaded = true;
          //console.log("Appuntamenti caricati da payload:", action.payload)
        },

        didInteractiveSessionsloaded:(state: any, action: PayloadAction<any>) =>  
        {
          state.interactiveSessions = action.payload;
          //console.log("Sessioni pratiche caricate da payload:", action.payload)
        },

        
        didSessionResourcesLoaded:(state: any, action: PayloadAction<any>) =>  
        {
          
          const result = action.payload;
          //console.log("didSessionResourceloaded payload:", result);
          const sessionId = result["id"];
          const videoItems =  result["items"];
          state.sessionResources[sessionId]= videoItems
        },

      deleteAppointment:(state: any, action: PayloadAction<any>) =>  
      {
        //console.log(`Richiesta cancellazione appuntamenti n.${action.payload.id}`);
        if (action.payload.type?.startsWith(INTERACTIVE_SESSION_TYPE)) 
        state.interactiveSessions = state.interactiveSessions.filter((isession: { id: any; })=> isession.id!=action.payload.id);
        else
        state.events = state.events.filter((event: { id: any; })=> event.id!=action.payload.id);
      }
      
    }
  })
  
  export const { actions, reducer }: any = appointmentsSlice
 
  export const selectors = {

    getAmountOfBookedAppointments: (state:any) => 
    {
      return state.appointments.bookedEvents == null ? -1 : state.appointments.bookedEvents.length;
    },

    getAppointments: (state: any) => {
      ////console.log("Richiamato getAppointments selector");
      return state.appointments.events
    },

    getInteractiveSessionById: (id:any)=> (state:any) =>{
      if (state.appointments.interactiveSessions==null) return null;
      else return state.appointments.interactiveSessions.find((isession:any)=> isession["id"]==id)
    },
    
    getInteractiveSessions:  (state: any) => {
      return state.appointments.interactiveSessions},
    
    getAppointmentsOrInteractiveSessions: (type:any) => (state:any)=>
    {
      if (type?.startsWith(INTERACTIVE_SESSION_TYPE))
      return state.appointments.interactiveSessions;
      else return state.appointments.events;
    },

    getSessionResources: (state: any) => {
        return state.appointments.sessionResources},
    
    areAppointmentsLoaded: (state: any) => {
        return state.appointments.eventsLoaded},

    isLoadingDirectorDashboard: (state: any) => {
      return state.appointments.isLoadingDirectorDashboard},

    getDirectors:  (state: any) => {
      return state.appointments.directors},
    
    getInteractiveDevices:  (state: any) => {
        return state.appointments.interactiveDevices},

   

    getAllSessionDirectors:  (state: any) => {
        return state.appointments.sessionDirectors},
    
    getSessionDirectors:  (id:any)=> (state: any) => {
          return state.appointments.sessionDirectors[id]},

    getNextValidId: (state: any) => Math.floor(Math.random()*1000) + 1,

    getAppointmentstate : (id :any) =>  (state: any) => 
    {
       
      const appointment = state.appointments.events.filter((el: any)=> el.id===id );
      const result = (appointment==null || appointment.state ==null)  ? Appointmentstate.NOT_FOUND.valueOf() : appointment.state;
      //console.log(`Stato dell'evento ${id}:${result}`);
      return result;
    }
  }
  