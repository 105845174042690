import { API } from 'aws-amplify';
import { loader } from 'graphql.macro';

const getAnalyticsSessionsByToken = async (
    group: any, passcode:any, nextToken:any) => {
  
    const query = loader('../graphql/listAnalyticsSessions.gql');
    //console.log('listAnalyticsSessions query: ', query)
    try {
      const result: any = await API.graphql({
        query: query,
        variables: { "group" : group, "passcode" : passcode, limit:200, "nextToken" : nextToken }
      });
      return result;
    } catch (error) {
      //console.log("Error in listAnalyticsSessions Query", error)
      throw error
    }
  }

export const listAnalyticsSessions = async (
  group: any, passcode:any) => {
  try {
    let nextToken = ""
    let analyticsSessions = []
    while (nextToken!=null)
    {
      const result = await getAnalyticsSessionsByToken(group, passcode, nextToken=="" ? null : nextToken)
      nextToken =  result["data"]["listAnalyticsSessions"]["nextToken"];
      analyticsSessions.push(...result["data"]["listAnalyticsSessions"]["items"]);
    }
    //console.log("listAnalyticsSessions result:", analyticsSessions);
    return analyticsSessions
    
  } catch (error) {
    //console.log("Error in listAnalyticsSessions Query", error)
    throw error
  }
}

export const createAnalyticsSession = async (group: String,
    ulid : String,
    passcode: String,
    content: any) => {
  
    const mutation = loader('../graphql/createAnalyticsSession.gql');
    //console.log('createAnalyticsSession mutation: ', mutation)
    try {
      const result: any = await API.graphql({
        query: mutation,
        variables: { group, ulid, passcode, content }
      });
      return result
  
    } catch (error) {
      //console.log("Error in createAnalyticsSession Mutation", error)
      throw error
    }
  }
  