//https://it.reactjs.org/docs/conditional-rendering.html
//https://stackoverflow.com/questions/58277981/how-to-create-a-3-column-layout-structure-in-react-js
//https://www.npmjs.com/package/react-contextmenu
//https://stackoverflow.com/questions/10970958/get-a-color-component-from-an-rgb-string-in-javascript
// dipendenze
//yarn add @material-ui/core
//yarn add @material-ui/icons
//yarn add rc-slider
//yarn add validate-image-url
//yarn add uuidv4
//yarn add react-modal-image
//yarn add react-datetime -> OK
//yarn add react-color

//https://github.com/react-component/slider
//https://www.npmjs.com/package/get-video-duration
//https://www.npmjs.com/package/validate-image-url


// WebVTT Parser and Editor
//https://www.npmjs.com/package/webvtt-parser
//https://github.com/funkyremi/vtt-live-edit
import { TimeDurationInput } from 'react-time-duration-input';
import { validator } from './Utils';
import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { TimeSeries } from "pondjs";
import { TraceEvent, TrackEvent } from './TimelineWatcher';
import { WebVTTParser } from 'webvtt-parser';
import { ThumbnailsBuilder, ThumbnailsRenderer } from './ThumbnailsManager'
import { TimePicker } from './TimelineToolbar'
import * as _ from 'lodash';
import { generateThumbnailsZipFile, extractThumbnailsFromZipFileUrl } from '../../utils/thumbnailsGenerator';
import { getFormattedTime } from './Utils'
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { Component } from 'react';
import { useEffect } from 'react';
import {
  Button, ButtonGroup, ButtonToolbar, Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardText, CardHeader, CardLink, CardImg, CardTitle
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import ReactPlayer from 'react-player';

//import "./LongTextStyles.css"
import { useIsOverflow } from "./Utils"
import { ClampedDiv } from '../../styles/styles';



import {
} from 'reactstrap';

import Slider from 'rc-slider';
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { DefItemColors } from './Constants';
import Lightbox from 'react-image-lightbox';
import { FiUpload, FiDownload } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai"
import { TfiVideoClapper } from "react-icons/tfi"
import { FaRegFileVideo } from "react-icons/fa";
import { BiCaptions } from 'react-icons/bi';
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import DateTime from 'react-datetime';
import { TrackType } from './Constants';
import { VideoSessionPicker } from './VideoSessionPicker'
import { IT, GB } from 'country-flag-icons/react/3x2'
import classNames from "classnames";
import moment from 'moment';
import { connect } from "react-redux";
import { actions as ExperimentsActions } from '../../store/slices/experiments'
import UploadingModal from '../../pages/timelineDetails'
import { Toolbar } from '@material-ui/core';
import { TiThSmallOutline } from 'react-icons/ti';
import VideoTranscriptionPanel from './VideoTranscriptionManager';
const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm:ss";


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);


export const ReadMoreText = ({ text, maxLines = 3, lineHeight = 20 }) => {
  const [clamped, setClamped] = React.useState(true);
  const [showButton, setShowButton] = React.useState(true);
  const { t } = useTranslation('tl', { useSuspense: false });
  const containerRef = React.useRef(null);
   
  // Aggiungi un ref per tracciare lo stato di montaggio
  const isMounted = React.useRef(true);

  const handleClick = () => setClamped(!clamped);

  const { isOverflow, numberOfLines } = useIsOverflow(containerRef, lineHeight);

  useEffect(() => {
    // Imposta il flag di montaggio a true quando il componente è montato
    isMounted.current = true;

    
   
    // Aggiorna lo stato solo se il componente è ancora montato
    if (isMounted.current) {
      setShowButton(numberOfLines > maxLines || isOverflow);
       //console.log(`RMT: isOverflow:${isOverflow}, numberOfLines:${numberOfLines}`, containerRef.current.styles);
    }
    // Cleanup function
    return () => {
      // Imposta il flag di montaggio a false quando il componente si smonta
      isMounted.current = false;
    };

  }, [isOverflow, numberOfLines])

  return (
    <>
      <ClampedDiv
        ref={containerRef}
        clamped={clamped}
        lineHeight={lineHeight}
        numLines={`${maxLines}`}
      >
        {text}
      </ClampedDiv>



      {showButton && (
        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
          <Button style={{ padding: "0px" }} color="link" onClick={handleClick}>{clamped ? t("read_all") : t("read_less")}</Button>
        </div>

      )}
    </>
  );
};

class ModalItemEditorNT extends Component {

  constructor(props) {
    super(props);

    const item = this.props.item;

    if (item == null) {
      const start = moment(this.props.start).toDate();
      const end = this.props.track.type === TrackType.VIDEO ? start : moment(this.props.start).add(10, "minutes").toDate();
      //console.log(`In ModalItemEditor: end:${typeof end}`);
      const duration = moment(end).diff(moment(start), "seconds", true)
      this.state = {
        currentDateStart: start,
        currentDateEnd: end,
        duration: duration * 1000, // il componente gestisce i millisecondi,
        urlValue: "",
        usedUrlValue: "",
        titleValue: "",
        descriptionValue: "",
        currentDuration: duration * 1000, // espressa in millisecondi!
        startOffset: 0,
        currentStartOffset: 0,
        endOffset: duration * 1000,
        currentEndOffset: duration * 1000,
        invalidUrl: true,
        invalidTitle: true,
        invalidDuration: false,
        invalidStartOffset: false,
        invalidEndOffset: false,
        invalidStartDate: false,
        urlChecking: false,
        color: DefItemColors(this.props.track.type).color,
        bgColor: DefItemColors(this.props.track.type).bgColor,
        iotJsonDateUpdated: false, // indica se le date di inizio e fine sono state aggiornate in seguito alla lettura del JSON
        iotJsonDateUpdating: false, // indica se è in corso il parsing del Json per la verifica delle date (puo' impiegare diversi secondi)
        invalidJson: false,
        uploadTypeRequest: null, // indica il tipo di upload ("video" | "subtitles_IT" | "subtitles_EN"),
        subtitlesUrl_IT: "",
        isValidVttUrl_IT: true,
        subtitlesUrl_EN: "",
        isValidVttUrl_EN: true,
        thumbnailsUrl: "",
        thumbnails: {},
        buildingThumbnails: false,
        thumbnailsRequestCode: 0
      }
    }
    else {
      this.state = {
        currentDateStart: moment(item.start_time).toDate(),
        currentDateEnd: moment(item.end_time).toDate(),
        duration: item.duration * 1000, // il componente gestisce i millisecondi
        usedUrlValue: item.source,
        urlValue: item.source,
        titleValue: item.title,
        descriptionValue: item.description,
        currentDuration: item.duration * 1000,
        startOffset: item.start_offset * 1000,
        currentStartOffset: item.start_offset * 1000,
        endOffset: item.end_offset * 1000,
        currentEndOffset: item.end_offset * 1000,
        invalidUrl: false,
        invalidTitle: false,
        invalidDuration: false,
        invalidStartOffset: false,
        invalidEndOffset: false,
        invalidStartDate: !moment(item.start_time).isValid(),
        urlChecking: false,
        color: item.color,
        bgColor: item.bgColor,
        iotJsonDateUpdated: false, // indica se le date di inizio e fine sono state aggiornate in seguito alla lettura del JSON
        iotJsonDateUpdating: false, // indica se è in corso il parsing del Json per la verifica delle date (puo' impiegare diversi secondi)
        invalidJson: false,
        subtitlesUrl_IT: (item.subtitlesUrl || item.subtitlesUrl_IT || ""),
        isValidVttUrl_IT: true,
        subtitlesUrl_EN: (item.subtitlesUrl_EN || ""),
        isValidVttUrl_EN: true,
        thumbnails: item.thumbnails || {},
        thumbnailsUrl: (item.thumbnailsUrl || ""),
        buildingThumbnails: false,
        thumbnailsRequestCode: 0
      }
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

    if (prevProps.uploadedResourceUrl !== this.props.uploadedResourceUrl) {
      //console.log("getUploadUrl Nuova url per itemEditor:", this.props.uploadedResourceUrl);
      this.handleUrlChange(this.props.uploadedResourceUrl);
      if (this.props.uploadedResourceUrl != null)
        this.setState({ modalUpload: false });
      //this.toggleUpload();
    }
  }


  handleVideoDuration = (duration) => {
    //console.log(`Richiamato handleVideoDuration  con duration: ${duration}`);
    // se non sto facendo una verifica della url, ignoro i cambiamenti 
    // di stato del video, per evitare che cancelli le impostazioni dei video
    // validi
    if (!this.state.urlChecking)
      return;

    if (duration == null || isNaN(duration)) {
      this.setState({
        invalidUrl: true, duration: 0, startOffset: 0, currentStartOffset: 0,
        endOffset: 0, currentEndOffset: 0
      },
        () => this.updateEndDatetime());
    }

    else {
      this.setState({
        invalidUrl: false, duration: duration * 1000, startOffset: 0,
        endOffset: duration * 1000, currentEndOffset: duration * 1000, urlChecking: false
      },
        () => this.updateEndDatetime());
    }
  }

  handleVideoError = (error) => {
    //console.log(`Richiamato handleVideoError: ${error}`);

    // se non sto facendo una verifica della url, ignoro i cambiamenti 
    // di stato del video, per evitare che cancelli le impostazioni dei video
    // validi
    if (!this.state.urlChecking)
      return;

    //console.log("Errore nel caricamento video");
    this.setState({
      invalidUrl: true, duration: 0, startOffset: 0, currentStartOffset: 0, urlChecking: false,
      endOffset: 0, currentEndOffset: 0
    },
      () => this.updateEndDatetime());
  }

  isMkvVideo = (url) => {
    return url != null && url.startsWith("http") && url.endsWith(".mkv")
  }

  handleUrlChange = async (event) => {
    let url
    //console.log("event url", event)
    if (event.target) {
      url = event.target.value;
    } else {
      url = event
    }

    if (this.state.uploadTypeRequest == "subtitles_IT" && this.props.track.type === TrackType.VIDEO) {
      const isValidVttUrl_IT = await this.isValidVtt(url);
      //console.log("UPLOAD_URL: Dentro handleUrlChange con subtitles_IT: isValidVttUrl_IT:" + isValidVttUrl_IT)
      this.setState({ subtitlesUrl_IT: url, isValidVttUrl_IT: isValidVttUrl_IT });
      return;
    }

    if (this.state.uploadTypeRequest == "subtitles_EN" && this.props.track.type === TrackType.VIDEO) {
      const isValidVttUrl_EN = await this.isValidVtt(url);
      //console.log("UPLOAD_URL: Dentro handleUrlChange con subtitles_EN: isValidVttUrl_EN:" + isValidVttUrl_EN)
      this.setState({ subtitlesUrl_EN: url, isValidVttUrl_EN: isValidVttUrl_EN });
      return;
    }

    if (this.state.uploadTypeRequest == "thumbnails" && this.props.track.type === TrackType.VIDEO) {

      //console.log("UPLOAD_URL: Dentro handleUrlChange con thumbnails url", url)
      this.setState({ thumbnailsUrl: url });
      return;
    }

    const valid = this.isValidUrl(url);
    //console.log(`Validità dell'url: ${valid}`, this.props.track.type);

    this.setState({ urlValue: url, invalidUrl: !valid, iotJsonDateUpdated: false });
    //console.log('state valid url', this.state)
    // se la url modificata risulta valida
    // e ho a che fare con un item di tipo VIDEO
    // provo  a ricaricare i metadati del video
    // in modo da fare un ulteriore check sulla validità della url
    if (valid && this.props.track.type === TrackType.VIDEO) {
      try {
        //console.log("Provo a vedere se posso caricare la url video....");

        if (!ReactPlayer.canPlay(url) && !this.isMkvVideo(url)) { //console.log("Non valida...");
          this.setState({ urlValue: url, invalidUrl: true });
        }
        else {
          //console.log("Url valida, mi accerto di leggere una durata di video valida...");
          this.setState({ urlChecking: true, urlValue: url, usedUrlValue: url });
        }

      } catch (ex) {
        //console.log(`Eccezione nel tentativo di caricare la url:${ex}`);
        this.setState({ urlValue: url, invalidUrl: !valid });
      }
    }
    // se ho a che fare con un item di tipo IOT provo a leggere la url
    // per vedere se si tratta di un json con informazioni temporali
    // sui campioni, in modo da suggerire il tempo iniziale e finale dell'item
    else if (valid && this.props.track.type === TrackType.IOT) {
      //console.log("[TODO] Leggere i dati del Json IOT per recuperare info su range temporale dei campioni");
      this.setJsonDataByUrl(url);
    }
  }


  /** Verifica la validità del Form
   *  Un form è valido se ha una durata maggiore di 0, un titolo, dei valori di data corretti e una url valida (a parte i tag)
   *  Nel caso di item Video, si richiede anche che abbia lo startOffset < endOffset
   */
  isValidForm = () => {
    const { invalidDuration, invalidTitle, invalidUrl,
      invalidStartDate, invalidStartOffset, invalidEndOffset,
      iotJsonDateUpdating, invalidJson, isValidVttUrl_IT, isValidVttUrl_EN,
      startOffset, endOffset } = this.state;
    //condizioni generali
    let isValid = !invalidDuration && !invalidTitle && !invalidStartDate &&
      !iotJsonDateUpdating && isValidVttUrl_IT && isValidVttUrl_EN;

    // tag
    if (this.props.track.type != TrackType.TAG) {
      isValid = isValid && !invalidJson && !invalidUrl && !invalidStartOffset && !invalidEndOffset && startOffset < endOffset;
    }
    return isValid;
  }

  closeModal = () => {
    //console.log("Richiamata closeModal()");
    this.props.toggle();
  };

  isValidVtt = async (url) => {
    if (url == null) return false;
    // se si invia un url vuoto si assume la url "valida" in quanto si ritiene che non
    // si intenda avvalersi dei sottotitoli (la funzione presente è usata per la
    // validazione del form)
    if (url == "") return true;

    try {

      let response = await fetch(url);
      let vttContent = await response.text();

      const parser = new WebVTTParser();
      const tree = parser.parse(vttContent, 'metadata');


      return (tree != null && tree.errors != null && tree.errors.length == 0);
    } catch (e) {
      console.error("VTT content: ERROR ON FETCH or Parsing" + e);
      return false;
    }
  }

  isValidUrl = (string) => {
    let url;
    try {
      url = new URL(string);
      //console.log('url path',url)

    } catch (_) {
      //console.log( `URL NON VALIDO: ${string}`);
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:" || url.protocol === "file:";
  }

  onChangeDateStart = (newDate) => {
    //console.log(`selezionata DateStart: ${newDate}`);
    try {
      const newDateStart = moment(newDate, DATE_TIME_FORMAT);
      //console.log(`selezionata DateStart riformattata: ${newDateStart}`);
      if (newDateStart.isValid()) {
        this.setState({ currentDateStart: newDateStart, invalidStartDate: false }, () => this.updateEndDatetime());
      }
      else {
        //console.log(`Invalid DateStart: ${newDate}`);
        this.setState({ invalidStartDate: true });
      }

    } catch (error) {
      //console.log(`Invalid DateStart: ${newDate} -> ${error}`);
      this.setState({ invalidStartDate: true });
    }

  }

  onChangeDateEnd = (newDate) => {
    //console.log(`selezionata DateEnd: ${newDate}`);
    //alert("tl:Cambiata data finale!");
    this.setState({ currentDateEnd: moment(newDate, DATE_TIME_FORMAT) });
  }


  handleTitleChange = (event) => {
    this.setState({ titleValue: event.target.value, invalidTitle: event.target.value == "" });
  }

  handleDescriptionChange = (event) => {
    this.setState({ descriptionValue: event.target.value });
  }

  /** imposta la durata dell'item
   * n.b: questo metodo agisce solo su item NON di tipo video
   * in cui l'endOffset viene impostato come valore di duration
   * @param newValue nuova durata espressa in millisecondi
   */
  setDuration = (newValue) => {
    //console.log(`Valore di duration:${newValue}`);
    this.setState({
      duration: newValue, endOffset: newValue,
      invalidDuration: newValue <= 0
    },
      () => this.updateEndDatetime());
  }

  setStartOffset = (newValue) => {
    //console.log(`Valore di startOffset:${newValue}`);
    this.setState({ startOffset: newValue }, () => this.updateEndDatetime());
  }

  setEndOffset = (newValue) => {
    //console.log(`Valore di endOffset:${newValue}`);
    this.setState({ endOffset: newValue }, () => this.updateEndDatetime());
  }

  onChangeOffsets = (newValue) => {
    //console.log("Nuovo valore:", newValue);
    this.setState({
      startOffset: newValue[0], endOffset: newValue[1],
      currentStartOffset: newValue[0], currentEndOffset: newValue[1]
    }, () => this.updateEndDatetime());

  }

  updateEndDatetime = () => {

    const { currentDateStart, startOffset, endOffset } = this.state;
    ////console.log(`MIEDITOR:updateEndTime StartOffset: ${startOffset} endOffset: ${endOffset} itemEO:${this.props.item.end_offset}`);
    const currentDateEnd = moment(currentDateStart).add(moment.duration(endOffset, 'milliseconds')).add(moment.duration(-startOffset, 'milliseconds'))
    this.setState({ currentDateEnd });
  }

  deleteItem = () => {
    const {
      onItemDeleted,
      item
    } = this.props;

    onItemDeleted(item);
    // chiusura della finestra
    this.closeModal();
  }

  /** Crea o modifica un item esistente sulla base degli inserimenti dell'utente */
  createItem = () => {
    const {
      onItemEdited,
      item,
      track,
      duplicate
    } = this.props;


    const { titleValue, urlValue, descriptionValue, currentDateStart, duration,
      startOffset, endOffset, currentDateEnd, color, bgColor, subtitlesUrl_IT, subtitlesUrl_EN, thumbnailsUrl } = this.state;

    //console.log(`Da createItem è stato passato il gruppo ${track.id} Duration:${duration}`);
    //console.log(`ItemEditor startDate:${moment(currentDateStart)} start_offset: ${startOffset}`);
    const new_item = {
      id: ((item == null || duplicate == true) ? uuidv4() : item.id),
      track: track.id,
      color: color,
      bgColor: bgColor,
      type: track.type,
      title: titleValue,
      description: descriptionValue,
      source: urlValue,
      start_time: moment(currentDateStart),
      duration: duration / 1000.0, // l'item ha una durata espressa in secondi
      end_time: moment(currentDateEnd),
      canMove: true,
      canResize: (track.type === TrackType.VIDEO || track.type === TrackType.IOT) ? false : "right",
      canChangeGroup: (track.type === TrackType.VIDEO || track.type === TrackType.TAG) ? false : true,
      start_offset: startOffset / 1000,
      end_offset: endOffset / 1000,
      subtitlesUrl_IT: subtitlesUrl_IT || "",
      subtitlesUrl_EN: subtitlesUrl_EN || "",
      thumbnailsUrl: thumbnailsUrl || ""
    }

    //console.log(`MIEDITOR: salvo endOffset: ${endOffset}`)
    // callback di creazione dell'item
    onItemEdited(new_item);
    // chiusura della finestra
    this.closeModal();
  }


  handleColorChange = (color, event) => {
    //console.log(`Nuovo colore in ItemEditor: ${color}`);
    this.setState({ color });
  }

  handleBgColorChange = (bgColor, event) => {
    //console.log(`Nuovo bgcolore in ItemEditor: ${bgColor}`);
    this.setState({ bgColor });
  }
  toggleUpload = () => {
    this.setState({ modalUpload: !this.state.modalUpload });
  }

  toggleImportVideo = () => {
    this.setState({ modalImportVideo: !this.state.modalImportVideo });
  }

  toggleImportVideoTranscription = () => {
    this.setState({ modalImportVideoTranscription: !this.state.modalImportVideoTranscription });
  }


  uploadFile = (file) => {
    // UPLOAD DEL FILE TIMELINE
    this.props.willUploadFile(file);
    //console.log('uploading file->', file);
  }

  setJsonDataByUrl = async (url) => {
    if (url == null) {
      this.setState({ iotJsonDateUpdated: false, invalidJson: true });
      return;
    }

    try {

      //console.log(`Leggo il json dalla url -> ${url}`);
      this.setState({ iotJsonDateUpdating: true });

      let response = await fetch(url);
      let responseJson = await response.json();
      let pointsArray = responseJson.points;
      if (responseJson["type"] == "posix") {

        let tagKeys = Object.keys(responseJson.points);
        //console.log(`DATO DI TIPO POSIX con TAGS: ${tagKeys}`);
        pointsArray = responseJson.points[tagKeys[0]];
      }
      else {
        //console.log("DATO NON DI TIPO POSIX");
      }

      responseJson.points = pointsArray.map((point) => {
        return point.map((p, index) => {
          if (index === 0) return parseInt(p);
          else
            return parseFloat(p)
        })

      });

      ////console.log(`Colonne da Json: ${responseJson.columns}`);
      ////console.log(`Punti da Json: ${responseJson.points}`);

      // Data
      // http://software.es.net/pond/#/class/timeseries
      const series = new TimeSeries({
        name: responseJson.name,
        columns: responseJson.columns, // Es: ["time", "temperatura 1", "temperatura 2"]
        points: responseJson.points
      });

      //console.log("Serie caricata:");
      //console.log(`Nome:${series.name}`);
      //console.log(`Columns:${series.columns}`);
      //console.log(`Points -->:${series.points}`);

      //console.log(series);     
      //console.log(`series:${series}`);
      if (series != null) {
        //console.log(`->ITEM EDITOR: Data primo campione:${responseJson.points[0][0]}`);
        //console.log(`->ITEM EDITOR: Primo campione della serie datato a ${moment(responseJson.points[0][0])}`);
        //console.log(`->ITEM EDITOR: Primo valore della serie:${responseJson.points[0][1]}`);
        //console.log(`->ITEM EDITOR: Ultimo campione della serie datato a ${moment(responseJson.points[responseJson.points.length-1][0])}`);
        const iot_item_start_time = moment(responseJson.points[0][0]);
        const iot_item_end_time = moment(responseJson.points[responseJson.points.length - 1][0]);
        const iot_item_duration = moment.duration(iot_item_end_time.diff(iot_item_start_time));
        this.setState({ currentDateStart: moment(iot_item_start_time, DATE_TIME_FORMAT) },
          () => {
            this.setDuration(iot_item_duration);
            this.setState({ currentDuration: iot_item_duration })
          });
      }
      this.setState({ iotJsonDateUpdated: true, iotJsonDateUpdating: false, invalidJson: false });
      //console.log("JSON VALIDO");
    } catch (error) {
      //console.log("JSON NON VALIDO");
      console.error(`ITEM EDITOR: Errore nel parsing del JSON coi dati IOT: ${error}`);
      this.setState({
        iotJsonDateUpdated: false, iotJsonDateUpdating: false,
        invalidJson: true // aggiunto nella nuova versione: da testare

      });
    }
  }

  addLineBreaks = string =>
    string.split('\n').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  renderThumbnails = () => {
    const { thumbnails } = this.state;
    //const dataSmile = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4"

    const seconds = Object.keys(thumbnails)
    return (seconds.map((second) => {
      return (<>
        <img style={{ marginRight: "1px", border: "1px solid #555" }} width="20px"
          src={thumbnails[second]} alt={`Thumb at sec.${second}`} />
      </>)
    }))
  }

  render() {
    const {
      className,
      isOpen,
      toggle,
      track,
      t
    } = this.props;


    const { currentDateStart, currentDateEnd, titleValue, urlValue, descriptionValue,
      currentDuration, startOffset, endOffset, duration,
      currentStartOffset, currentEndOffset, invalidUrl, invalidTitle, invalidDuration, invalidStartDate, urlChecking,
      invalidJson, iotJsonDateUpdated, iotJsonDateUpdating, subtitlesUrl_IT, isValidVttUrl_IT, subtitlesUrl_EN, isValidVttUrl_EN, thumbnails } = this.state;

    //alert(`Istante iniziale:${ currentDateStart.toDateString() }`);
    const butOkLabel = (this.props.item == null || this.props.duplicate) ? t("tl:Aggiungi") : t("tl:Conferma_modifiche");
    const titleLabel = (this.props.item == null || this.props.duplicate) ? t("tl:Aggiunta") : t("tl:Modifica");
    const invalidUrlFeedback = (urlChecking ? t("tl:url_checking") :
      (invalidJson ? t("tl:invalidJsonUrl") : t("tl:invalid_url")));


    const itemType = this.props.track.type; //  (this.props.track.id==TAGS_TRACK_ID) ? "Tag" : "Risorsa";

    const itemColor = this.state.color; //(this.props.item==null) ? DefItemColors(track.type).color : this.props.item.color;
    const itemBgColor = this.state.bgColor; //(this.props.item==null) ? DefItemColors(track.type).bgColor : this.props.item.bgColor;

    ////console.log("IsValidVTT:" + isValidVttUrl_IT);

    return (
      <div>

        <Modal isOpen={isOpen} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}> {titleLabel} {itemType === TrackType.VIDEO ? "Audio/Video" : t(`tl:${itemType}`)}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <div><b>{t("tl:track_type")}:</b> {track != null ? t(`tl:${track.title}`) : "Non pervenuto"} </div>
              </FormGroup>

              <FormGroup>
                <Label for="txtTitle">
                  <b>{t("tl:name")}</b></Label>
                {
                  invalidTitle ? (<Input invalid id="txtTitle" style={{ 'width': '100%' }} type="text" value={titleValue} onChange={this.handleTitleChange} />
                  ) :
                    (<Input valid id="txtTitle" style={{ 'width': '100%' }} type="text" value={titleValue} onChange={this.handleTitleChange} />
                    )
                }
                <FormFeedback>{t("tl:item_editor_enter_name")}</FormFeedback>
              </FormGroup>

              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div><b>{t("tl:background_color")}</b><ColorPicker color={itemBgColor} type={itemType} onColorChanged={this.handleBgColorChange} />
                </div>
                <div><b>{t("tl:foreground_color")}</b><ColorPicker color={itemColor} type={itemType} onColorChanged={this.handleColorChange} /></div>
              </div>
              <p></p>

              <FormGroup>
                <Label for="txtDescription" >
                  <b>{t("tl:description")}</b></Label>
                <textarea id="txtDescription" style={{ 'width': '100%' }} type="text" value={descriptionValue}
                  onChange={this.handleDescriptionChange} />
              </FormGroup>

              {itemType != TrackType.TAG && (
                <FormGroup>
                  <Label for="txtUrl">
                    <b>{itemType === TrackType.VIDEO ? "Url audio/video" : t("tl:url_item", { itemType })}</b> </Label>
                  <FiUpload cursor="pointer" data-place="top" data-tip={t("tl:Upload_File")}
                    style={{ marginLeft: "10px", backgroundColor: `white` }}
                    onClick={() => this.setState({ uploadTypeRequest: "video" }, () => this.toggleUpload())}
                  />

                  {/* pulsante disabilitato in quanto il back non è stato ancora implementato */}
                  {itemType === TrackType.VIDEO &&

                    <FaRegFileVideo cursor="pointer" style={{ marginLeft: "10px", backgroundColor: `white` }}
                      onClick={() => this.toggleImportVideo()} data-place="top" data-tip={t("tl:Import_Video")} />

                  }

                  {
                    (invalidUrl || invalidJson) ? (<Input invalid id="txtUrl" type="text" value={urlValue} onChange={this.handleUrlChange} />) :

                      (<Input valid id="txtUrl" type="text" value={urlValue} onChange={this.handleUrlChange} />

                      )
                  }

                  <FormFeedback>{invalidUrlFeedback}</FormFeedback>
                  {
                    iotJsonDateUpdated && (<FormFeedback valid>{t("tl:iotJsonDatesUpdated")}</FormFeedback>)

                  }

                  {
                    iotJsonDateUpdating && (<FormFeedback valid>{t("tl:iotJsonDatesUpdating")}</FormFeedback>)

                  }


                </FormGroup>
              )
              }

              {
                itemType == TrackType.VIDEO && (
                  <>
                    <FormGroup>
                      <Label for="txtUrl">
                        <b>{t("tl:Url sottotitoli")}</b> </Label>

                      {itemType === TrackType.VIDEO && this.props.item!=null &&

                        <BiCaptions  cursor="pointer" style={{  marginLeft: "10px", fontSize:"1.2em", backgroundColor: `white` }}
                          onClick={() => this.toggleImportVideoTranscription()} data-place="top" data-tip={t("tl:Import_VideoTranscription")} />

                      }


                      <div style={{ display: "flex" }}>
                        <IT style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="Italiano" />
                        {isValidVttUrl_IT == true ?
                          <Input valid id="subtitlesUrl_IT" type="text" value={subtitlesUrl_IT} onChange={async (e) => {
                            const urlContent = e.target.value;
                            //console.log("SubtitlesUrl IT: (subtitlesUrl_IT changed!" +urlContent);
                            const isValidVttUrl_IT = await this.isValidVtt(urlContent);
                            //console.log(`VTT IT  URL changed: ${urlContent} VALID: ${isValidVttUrl_IT}`);
                            this.setState({ subtitlesUrl_IT: urlContent, isValidVttUrl_IT: isValidVttUrl_IT })
                          }
                          } /> :

                          <Input invalid id="subtitlesUrl_IT" type="text" value={subtitlesUrl_IT} onChange={async (e) => {
                            const urlContent = e.target.value;
                            //console.log("SubtitlesUrl IT: (subtitlesUrl_IT changed!" +urlContent);
                            const isValidVttUrl_IT = await this.isValidVtt(urlContent);
                            //console.log(`VTT IT URL changed: ${urlContent} VALID: ${isValidVttUrl_IT}`);
                            this.setState({ subtitlesUrl_IT: urlContent, isValidVttUrl_IT: isValidVttUrl_IT })
                          }
                          } />

                        }
                        <FiUpload cursor="pointer" style={{ margin: "10px", backgroundColor: `white` }}
                          onClick={() => this.setState({ uploadTypeRequest: "subtitles_IT", subtitlesUrl_IT: "" }, () => this.toggleUpload())} data-place="top" data-tip={t("tl:Upload_File")} />

                      </div>

                      <div style={{ display: "flex" }}>
                        <GB style={{ "margin": "5px", border: "0px solid #555" }} width="25px" title="English" />
                        {isValidVttUrl_EN == true ?
                          <Input valid id="subtitlesUrl_EN" type="text" value={subtitlesUrl_EN} onChange={async (e) => {
                            const urlContent = e.target.value;
                            //console.log("SubtitlesUrl EN: (subtitlesUrl_EN changed!" +urlContent);
                            const isValidVttUrl_EN = await this.isValidVtt(urlContent);
                            //console.log(`VTT EN  URL changed: ${urlContent} VALID: ${isValidVttUrl_EN}`);
                            this.setState({ subtitlesUrl_EN: urlContent, isValidVttUrl_EN: isValidVttUrl_EN })
                          }
                          } /> :

                          <Input invalid id="subtitlesUrl_EN" type="text" value={subtitlesUrl_EN} onChange={async (e) => {
                            const urlContent = e.target.value;
                            //console.log("SubtitlesUrl EN: (subtitlesUrl_EN changed!" +urlContent);
                            const isValidVttUrl_EN = await this.isValidVtt(urlContent);
                            //console.log(`VTT EN URL changed: ${urlContent} VALID: ${isValidVttUrl_EN}`);
                            this.setState({ subtitlesUrl_EN: urlContent, isValidVttUrl_EN: isValidVttUrl_EN })
                          }
                          } />

                        }
                        <FiUpload cursor="pointer" style={{ margin: "10px", backgroundColor: `white` }}
                          onClick={() => this.setState({ uploadTypeRequest: "subtitles_EN", subtitlesUrl_EN: "" }, () => this.toggleUpload())} data-place="top" data-tip={t("tl:Upload_File")} />

                      </div>
                    </FormGroup>


                    {this.state.duration > 0 &&
                      <FormGroup>

                        <Label>
                          <b>{`Thumbnails (${Object.keys(thumbnails).length})`}</b>
                        </Label>

                        <TfiVideoClapper cursor="pointer" style={{ margin: "10px", backgroundColor: `white` }}
                          onClick={() => this.setState((prevState) => ({ buildingThumbnails: true, thumbnailsRequestCode: (prevState.thumbnailsRequestCode + 1 % 10) }))}
                          data-place="top" data-tip={t("tl:regenerate_thumbnails")} />

                        {this.state.thumbnailsUrl && !this.state.buildingThumbnails &&
                          <>

                            <AiFillDelete cursor="pointer" onClick={(e) => { this.setState({ thumbnailsUrl: "", thumbnails: {} }) }}
                              style={{ marginLeft: "10px", margin: "0px", backgroundColor: `white` }} data-place="top" data-tip={t("tl:delete_thumbnails")} />

                            <IconButton
                              href={this.state.thumbnailsUrl}
                              target={"_blank"}
                              download
                              style={{ margin: "0px", backgroundColor: `white` }}
                            >
                              <IconContext.Provider value={{
                                color: `black`, className: "global-class-name", padding: "0px",
                                size: "0.7em"
                              }}>
                                <FiDownload data-place="top" data-tip={t("tl:download_thumbnails")} />
                              </IconContext.Provider>
                            </IconButton>
                          </>

                        }
                        <div style={{ overflowX: "scroll" }}>
                          <ThumbnailsRenderer key={this.state.thumbnailsUrl} item={{ type: TrackType.VIDEO, thumbnailsUrl: this.state.thumbnailsUrl, start_offset: startOffset / 1000, end_offset: endOffset / 1000, duration: duration / 1000 }}
                            thumbnails={this.props.item == null ? this.state.thumbnails : null}
                            preview
                            showTime
                            onLoaded={(thumbnailsLoaded) => { this.setState({ thumbnails: thumbnailsLoaded }) }}
                            itemContext={{ dimensions: { width: "100px", height: "80px" } }} />
                          <div style={{ marginTop: "20px", height: "10px", width: "100%" }}></div>
                        </div>
                        {this.state.buildingThumbnails &&
                          <ThumbnailsBuilder item={this.props.item || {
                            source: urlValue, start_offset: startOffset / 1000, end_offset: endOffset / 1000,
                            duration: duration / 1000
                          }}
                            requestCode={this.state.thumbnailsRequestCode}
                            onEnd={(newThumbnails) => {
                              //console.log("TB: Thumbnails created:", newThumbnails)
                              this.setState((prevState) => ({
                                thumbnails: newThumbnails,
                                buildingThumbnails: false
                              }),
                                async () => {
                                  // generate Zip after thumbnails build!
                                  const blob = await generateThumbnailsZipFile(this.props.item, newThumbnails)
                                  //console.log("TB: Thumbnails Zip blob:", blob);
                                  this.setState({ uploadTypeRequest: "thumbnails" }, () => this.uploadFile(blob))
                                })
                            }
                            }
                          />

                        }

                      </FormGroup>}


                  </>
                )
              }



              {itemType != TrackType.VIDEO && itemType != TrackType.IOT && (
                <FormGroup>
                  <Label for="txtDuration" style={{ marginRight: "5px" }}>
                    <b>{`${t("tl:Durata")}:  `}</b></Label>
                  <TimeDurationInput id="txtDuration"
                    value={currentDuration}
                    onChange={(newValue) => this.setDuration(newValue)} />
                  {invalidDuration && <FormText><span style={{ color: "red" }}>{t("tl:invalidDuration")}</span></FormText>}

                </FormGroup>
              )}

              {(itemType == TrackType.VIDEO || itemType == TrackType.IOT) && (
                <div id="videoId">

                  <FormGroup>
                    <label style={{ marginRight: '0.8rem' }}>
                      <b>{itemType == TrackType.VIDEO ? t("tl:video_duration") : t("tl:iot_duration")}:</b> {moment.utc(moment.duration(duration, 'milliseconds').asMilliseconds()).format(TIME_FORMAT)}
                    </label>
                  </FormGroup>

                  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                    <div><b>{itemType == TrackType.VIDEO ? t("tl:video_start") : t("tl:iot_start")}</b></div>
                    <div><b>{itemType == TrackType.VIDEO ? t("tl:video_end") : t("tl:iot_end")}</b></div>
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                    <div>
                      <TimeDurationInput
                        value={currentStartOffset}
                        onChange={(newValue) => this.setStartOffset(newValue)} />
                    </div>
                    <div>
                      <TimeDurationInput
                        value={currentEndOffset}
                        onChange={(newValue) => this.setEndOffset(newValue)} />
                    </div>

                  </div>
                  <p></p>

                  <div style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridGap: 20 }}>
                    <Range min={0} max={duration} step={1}
                      value={[startOffset, endOffset]}
                      onChange={this.onChangeOffsets}
                      tipFormatter={value => moment.utc(moment.duration(value, 'milliseconds').asMilliseconds()).format(TIME_FORMAT)}
                      allowCross={false}

                    />
                  </div>

                </div>
              )}
              <p></p>


              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div><b>{t("tl:start")}</b></div>
                <div><b>{t("tl:end")}</b></div>
              </div>

              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div>
                  <FormGroup>
                    {/*                          
              <DateTime
                   onChange={this.onChangeDateStart}
                   dateFormat={DATE_FORMAT}
                   timeFormat={TIME_FORMAT}
                   initialValue={ moment(currentDateStart).format(DATE_TIME_FORMAT) }
                  />
               */}


                    <TimePicker firstItemStartTime={this.props.firstItemStartTime}
                      lastItemEndTime={this.props.lastItemEndTime}
                      currentPositionDate={moment(currentDateStart)}
                      onChange={this.onChangeDateStart}
                    />


                    {invalidStartDate && <FormText><span style={{ color: "red" }}>{t("tl:invalidDate")}</span></FormText>}

                  </FormGroup>
                </div>
                <div>
                  <Input readOnly type="text"
                    value={`${getFormattedTime(this.props.firstItemStartTime, currentDateEnd)}`} />
                </div>

              </div>
              <ReactTooltip />
            </Form>

          </ModalBody>
          <ModalFooter>
            <Button disabled={!this.isValidForm()} color="primary" onClick={this.createItem}>{butOkLabel}</Button>{' '}
            <Button color="secondary" onClick={this.closeModal}>{t("tl:Ignora")}</Button>
            {this.props.item != null && !this.props.duplicate && <Button color="danger" onClick={this.deleteItem}>{t("tl:Elimina")}</Button>}

          </ModalFooter>
        </Modal>

        <div style={{ display: 'none' }}>
          <ReactPlayer
            url={this.state.usedUrlValue}
            onError={this.handleVideoError}
            onDuration={this.handleVideoDuration}
          />
        </div>

        <Modal isOpen={this.state.modalImportVideo} toggle={this.toggleImportVideo} className={className}>
          <ModalHeader toggle={this.toggleImportVideo}>{t("tl:Import_Video")}</ModalHeader>
          <ModalBody>
            <VideoSessionPicker onFileUrlSelected={(selectedUrl) => {
              //console.log("Selezionato url video:", selectedUrl);
              //this.setState({urlValue :  selectedUrl});
              this.handleUrlChange(selectedUrl);
              this.toggleImportVideo();
            }} />
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalImportVideoTranscription} size="lg" style={{maxWidth: '60%'}}
        toggle={this.toggleImportVideoTranscription}>
          <ModalHeader 
          toggle={this.toggleImportVideoTranscription}>
            {`${t("tl:Import_VideoTranscription")}`}</ModalHeader>
          <ModalBody>
           <VideoTranscriptionPanel 
           experimentId={this.props.experimentId} 
           videoItem={this.props.item}
           onTranscriptionSelected={(videoTranscription) =>{
            this.setState({"subtitlesUrl_IT" : videoTranscription.subtitlesUrl_IT, 
                           "subtitlesUrl_EN": videoTranscription.subtitlesUrl_EN,
                            "modalImportVideoTranscription" : false
                            });
                          }}
            
           />
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalUpload} toggle={this.toggleUpload} className={className}>
          <ModalHeader toggle={this.toggleUpload}>{t("tl:Upload_File")}</ModalHeader>
          <ModalBody>
            <Label for="exampleFile">File</Label>
            <Input type="file" name="file" id="exampleFile" onChange={(event) => {
              //console.log(`UPLOAD_URL: Selezionato file ${event.currentTarget.files[0]} di tipo ${this.state.uploadTypeRequest} ${event.currentTarget.files[0]}`)
              //console.log(`UPLOAD_URL: Dettagli file`, event.currentTarget.files[0])

              if (this.state.uploadTypeRequest == "video")
                this.setState({ fileUrl: event.currentTarget.files[0] });
              else if (this.state.uploadTypeRequest == "subtitles_IT")
                this.setState({ subtitlesUrl_IT: event.currentTarget.files[0] });
              else if (this.state.uploadTypeRequest == "subtitles_EN")
                this.setState({ subtitlesUrl_EN: event.currentTarget.files[0] });
            }} />


          </ModalBody>
          <ModalFooter>
            <Button disabled={

              !((this.state.fileUrl != null && this.state.uploadTypeRequest == "video")
                || (this.state.subtitlesUrl_IT && this.state.uploadTypeRequest == "subtitles_IT")
                || (this.state.subtitlesUrl_EN && this.state.uploadTypeRequest == "subtitles_EN")
              )

            } color="primary" onClick={() => {
              if (this.state.uploadTypeRequest == "video") {
                this.uploadFile(this.state.fileUrl)
                //console.log("FILE_URL:",this.state.fileUrl);
              }

              else if (this.state.uploadTypeRequest == "subtitles_IT") {
                //console.log("Richiesta di upload del file:",this.state.subtitlesUrl_IT );
                this.uploadFile(this.state.subtitlesUrl_IT)
              }

              else if (this.state.uploadTypeRequest == "subtitles_EN")
                this.uploadFile(this.state.subtitlesUrl_EN)


            }}>{t("tl:Upload_File")}</Button>{' '}
            <Button color="secondary" onClick={this.toggleUpload}>{t("tl:Ignora")}</Button>
          </ModalFooter>

        </Modal>
        <UploadingModal />

      </div>);
  }
}


export class ItemPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isImage: false,
      modalUpload: false,
      modalImportVideo: false,    
      modalImportVideoTranscription: false  
    }
  }

  async componentDidMount() {
    const url = this.props.item.source;
    //console.log(`Item Preview: montato componente su item ${url}`);
    await this.checkImageUrl(url);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.item.source !== this.props.item.source) {
      const imageUrl = this.props.item.source;
      await this.checkImageUrl(imageUrl);

    }
  }

  async checkImageUrl(url) {
    // verifica se la url è una immagine!
    try {
      ////console.log("Verifica url....");
      const res = await validator({ url: url, timeout: 10000 });
      ////console.log("TROVATA IMMAGINE: Url:", res);
      this.setState({ isImage: true });
    }
    catch (ex) {
      ////console.log("eccezione:",ex);
      ////console.log("Immagine non trovata");
      this.setState({ isImage: false });
    }
  }

  toggle = () => {
    ////console.log(`Invocato toogle!`);
    this.setState(
      prevState => ({ isOpen: !prevState.isOpen })
    );
  }

  showImage = (item) => {
    //console.log("sono in ShowImage");
    //console.log(`Show Image di ${item.source}`);
    this.setState({ zoomedImageItem: item });
  }

  onModalImageClosed = () => {
    this.setState({ zoomedImageItem: null });
  }

  render() {
    const { isImage } = this.state;
    const { item } = this.props;
    const itemLink = (item.source != null && item.source != "") ? item.source : "#";
    return (
      <div>
        <Card body outline color="secondary">
          <CardHeader>
            {
              item.type === TrackType.TAG ? (<CardTitle>
                <div style={{ display: "flex", justifyContent: "center", "padding": "2px", "color": item.color, "backgroundColor": item.bgColor }}>

                  <b><span style={{ "padding": "0px" }}>{item.title}</span></b>

                </div>
              </CardTitle>) :



                (<CardLink
                  onClick={() => {//console.log("Tracer: Click on item link"); 
                    this.props.onEventToWatch && this.props.onEventToWatch(TraceEvent.CLICK_ATTACHMENT, { 
                      "isImage": isImage, "isLink" : true, "item" : item });
                  }

                  } href={itemLink} target="_blank">
                  {item.title}
                </CardLink>)
            }
          </CardHeader>
          {
            isImage && (

              <CardImg onClick={() => {
                this.props.onEventToWatch && this.props.onEventToWatch(TraceEvent.CLICK_ATTACHMENT, { 
                  "isImage": true, "isLink" : false, "item" : item  });
                this.showImage(item)}}
                style={{ cursor: "pointer" }} top width="100%" src={item.source} alt={item.title} />

            )

          }
          <div style={{ margin: "10px" }}>
            <ReadMoreText text={item.description}
              maxLines={3}
              lineHeight={20} />
          </div>

        </Card>
        {
          this.state.zoomedImageItem != null &&
          (<Lightbox style={{ zIndex: 1000 }}
            mainSrc={this.state.zoomedImageItem.source}
            imageTitle={this.state.zoomedImageItem.title}
            imageCaption={this.state.zoomedImageItem.description}
            onCloseRequest={this.onModalImageClosed}
          />)
        }
      </div>
    );
  }
}


class ColorPicker extends React.Component {

  constructor(props) {
    super(props);
    //console.log(`COLOR:${props.color}`);
    const rgb = this.props.color.match(/\d+/g);

    this.state = {
      displayColorPicker: false,
      color: {
        r: rgb[0],
        g: rgb[1],
        b: rgb[2],
        a: rgb[3] == null ? 255 : rgb[3]
      },
    };

  }


  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb })
    const newColor = `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`;
    //console.log("Nuovo colore:", newColor);
    this.props.onColorChanged(newColor);
  };

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.9)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={this.handleClose} />
          <SketchPicker color={this.state.color} onChange={this.handleChange} />
        </div> : null}

      </div>
    )
  }
}
const ModalItemEditor = withTranslation()(ModalItemEditorNT);
//  export default ModalItemEditor;
const mapStateToProps = state => ({
  uploading: state.ui.uploading,
  uploadedResourceUrl: state.experiments.uploadedResourceUrl
})
export default connect(mapStateToProps, { willUploadFile: ExperimentsActions.willUploadFile })(ModalItemEditor);
