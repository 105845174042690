
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AnalyticsSessionsSelector, actions as AnalyticsSessionsActions } from '../../store/slices/passCodeUsers'
import { useEffect, useState, useRef } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  ButtonToolbar, ButtonGroup, Button, Modal, ModalBody, ModalFooter, ModalHeader,
  Card, Alert, CardBody, CardLink, Nav, NavItem, NavLink, TabContent, TabPane,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Container, Row, Col, Form, FormGroup, Label, Input, FormText
} from "reactstrap";

import "./schoolsAnalyzer.css"

import { GoFileZip } from "react-icons/go";

import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { getContest2024Trace } from "./contestDataBuilder";
import { ContestTimelinesAnalyzer } from './ContestTimelinesAnalyzer';

/**
 * 
 * Algoritmo di conteggio del punteggio
 * main_tl = timeline con copertura video media massima
 * other_tl = [] // altre timeline
 * 0.75 * (copertura_media_perc(main_tl)) + 0.25 * (copertura_media_allegati(main_tl)) +
 * 0.20 * sum( 0.75 * (copertura_media_perc(other_tl)) + 0.25 * (copertura_media_allegati(other_tl)) )
 */
export const ContestSchoolsAnalyzer = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const contest2024Sessions = useSelector(AnalyticsSessionsSelector.getContest2024Sessions)
  const contest2024Schema = useRef(null);
  const contest2024SchoolRows = useSelector(AnalyticsSessionsSelector.getContest2024Schools)

  useEffect(() => {
    //console.log("Sono nel costruttore: Carico TUTTE LE ANALYTICS SESSIONS");
    dispatch(AnalyticsSessionsActions.willLoadContest2024Sessions({ "decompress": true }));
    //dispatch(UserAction.willGetRegisteredUsers());
  }, [])

  useEffect(() => {
    if (contest2024Sessions == null) return;
    const filteredData = contest2024Sessions.filter((item) => {
      return item["passcode"].startsWith("contest2024trace__")
    }).map(
      (item) => {
        return [item["passcode"].toLowerCase(), item["content"]]
      }
    )
    contest2024Schema.current = getContest2024Trace(filteredData);
  }, [contest2024Sessions])

  useEffect(() => {
    console.log("contest2024Schema.current:", contest2024Schema.current);
    if (contest2024Schema.current)
      dispatch(AnalyticsSessionsActions.willBuildContest2024Schools(contest2024Schema.current))
  }, [contest2024Schema.current])


  const paginationOptions = {
    sizePerPageList: [{
      text: `5 ${t("sessioni")}`, value: 5
    }, {
      text: `10 ${t("sessioni")}`, value: 10
    },
    {
      text: t('Tutte le sessioni'), value: (contest2024Sessions ? contest2024Sessions.length : 100)
    }
    ], pageStartIndex: 1
  }

  const overviewFormatter = (cell, row) => {
    return (<span>
      <b>Studenti:</b>{`${row["tot_partecipants"]} ${t("di")} ${row["tot_rolled_partecipants"]}`}
      <br></br>
      <b>Timelines:</b>{row["tot_timelines"]}
    </span>)
  }

  const contest2024SchoolSessionsColumns = [
    {
      dataField: 'id',
      text: t("id"),
      sort: true,
      hidden: true
    },

    {
      dataField: 'school',
      text: t("School"),
      headerStyle: () => {
        return { width: '40%' };
      },
      sort: true,
      hidden: false
    },

    {
      dataField: 'classroom',
      text: t("Class"),
      headerStyle: () => {
        return { width: '8%' };
      },
      sort: true
    },

    {
      dataField: 'full_name',
      text: t("Teacher"),
      hidden: false,
      sort: true
    }
    ,
    {
      dataField: 'email',
      text: t("mail"),
      hidden: false,
      sort: true,
      hidden: true
    },
    {
      "dataField": "overview",
      text: t("Overview"),
      formatter: overviewFormatter
    },

    {
      dataField: 'score',
      text: t("score"),
      hidden: false,
      sort: true,
      formatter: (cell, row) => { return row["score"].toFixed(2) },
      headerStyle: () => {
        return { width: '8%' };
      },
    }
  ]

  const expandRow = {
    parentClassName: 'parent-expand-foo',
    expandColumnRenderer: ({ expanded }) => (expanded ? '-' : '+'),
    expandHeaderColumnRenderer: ({ expanded }) => (expanded ? '-' : '+'),
    renderer: row => (
      <ContestTimelinesAnalyzer schoolData={row} schema={contest2024Schema.current} />
    ),
    showExpandColumn: true
  };

  return <div>
    <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
      <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
        <CardTitle tag="h5">{t("Elenco delle sessioni degli studenti")}
          {contest2024SchoolRows && `(${t("Totale")}:${contest2024SchoolRows.length})`}
          <IconButton
            onClick={() => { }}
            style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
          >
            <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
              <GoFileZip color="white" data-place="top"
                data-for="analyticsSessions"
                data-tip={t("Esporta selezionati in formato ZIP")}
              />
            </IconContext.Provider>
          </IconButton>
        </CardTitle>
      </CardHeader>
      <CardBody>
        {
          contest2024SchoolRows != null ?
            <BootstrapTable bootstrap4
              keyField='id' data={contest2024SchoolRows || []}
              columns={contest2024SchoolSessionsColumns}
              expandRow={expandRow}
            />
            :
            <Alert>{t("Caricamento sessioni in corso")}</Alert>
        }

      </CardBody>
      <CardFooter>
      </CardFooter>
    </Card>
  </div>
}