import { call, put, takeLatest, select, all, fork, delay } from 'redux-saga/effects'
import { actions as PassCodeUserActions } from '../slices/passCodeUsers'
import * as AnalyticsSessionsApi from "../../api/analytics_sessions";
import { configuration } from "../../config"
import moment from 'moment';
import { compress, decompress } from 'compress-json'
import { willGetUserProfileAsDirector, willGetUser } from "./users"
import { getStudentsByCategory, calcolaPunteggioFinale } from '../../components/Contest2024/contestDataBuilder';
import { willGetPublicExperiment } from './experiments';
import { willGetStudentsByTeacherCode } from './users';
import { analyzeTrace } from '../../components/Contest2024/traceAnalyzerUtils'

const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export function* sagas() {
  yield takeLatest(PassCodeUserActions.willLogoutWithPassCode.type, willLogoutWithPassCode)
  yield takeLatest(PassCodeUserActions.willLoadAnalyticsSessions.type, willLoadAnalyticsSessions)
  yield takeLatest(PassCodeUserActions.willLoadContest2024Sessions.type, willLoadContest2024Sessions)
  yield takeLatest(PassCodeUserActions.willSaveContest2024Session.type, willSaveContest2024Session)
  yield takeLatest(PassCodeUserActions.willBuildContest2024Schools.type, willBuildContest2024Schools)
  yield takeLatest(PassCodeUserActions.willSaveContest2024StudentSessions.type, willSaveContest2024StudentSessions)
  yield fork(saveAllStudentsSessionsFromLocalStorage)
}


function* willLoadAnalyticsSessions(action: any): any {
  const group = `${configuration[stage]["analyticsSessionGroup"]}`
  //"TEST_BETA_NOAUTH_01";

  const compressedAnalytics_sessions = yield call(AnalyticsSessionsApi.listAnalyticsSessions, group, null);

  const analytics_sessions = yield compressedAnalytics_sessions.map((session: any) => {
    let temp = Object.assign({}, session);
    try {
      temp["content"] = JSON.stringify(decompress(JSON.parse(session["content"])))
      temp["recording_id"] = JSON.parse(temp["content"])?.recording_id || "-"
      temp["expected_recordings"] = JSON.parse(temp["content"])?.expected_recordings || 0;
      return temp;
      // nel caso la decompressione fallisca, restituisco la sessione originaria
    } catch (error) {
      return session
    }
  })

  //console.log("AnalyticsSessions:", analytics_sessions);
  yield put(PassCodeUserActions.setCurrentAnalyticsSessions(analytics_sessions));
}

function* willLoadContest2024Sessions(action: any): any {
  console.log("willLoadContest2024Sessions payload:", action.payload)
  const group = action.payload?.group || `${configuration[stage]["contest2024Group"]}`
  const toDecompress = action.payload?.decompress;
  const compressedAnalytics_sessions = yield call(AnalyticsSessionsApi.listAnalyticsSessions, group, null);

  const contest2024_sessions = yield compressedAnalytics_sessions.map((session: any) => {
    let temp = Object.assign({}, session);
    try {
      temp["content"] = JSON.stringify(toDecompress ? decompress(JSON.parse(session["content"]))
        : (JSON.parse(session["content"])))
      //temp["recording_id"] = JSON.parse(temp["content"])?.recording_id || "-"
      //temp["expected_recordings"] = JSON.parse(temp["content"])?.expected_recordings || 0;
      return temp;
      // nel caso la decompressione fallisca, restituisco la sessione originaria
    } catch (error) {
      return session
    }
  })

  //console.log("Contest2024essions:", analytics_sessions);
  yield put(PassCodeUserActions.setContest2024Sessions(contest2024_sessions));
}

function* buildContest2024SchoolData(item: any, contest2024trace: any): any {
 // const [teacherId, classroom] = item.sp
  const timelineIds = Object.keys(contest2024trace[item])
  const totTimelines = timelineIds.length;
  const students = getStudentsByCategory(contest2024trace, item)
  // totale studenti che hanno partecipato
  const totStudents = students.length;
  const tracesOverview = {} as any;

  // d3deab88-60dd-4e8d-8cec-1f13e2dba31b_4fsa
  const [teacherId, classroom] = item.split("_")
  console.log("SAGA CONTEST: esamino teacherId:", teacherId, )
  const rolledStudents = (yield call(willGetStudentsByTeacherCode,{"payload" : teacherId})).filter(
    (student:any) => {return student["contest2024"]==true  && classroom.toLowerCase()===
      `${student["schoolGrade"]}${student["schoolSection"]}`.toLowerCase()})
  console.log("rolled Students:", rolledStudents)

  //const rolledStudents = yield call(willGetStudentsByTeacherCode())
  for (let i = 0; i < timelineIds.length; i++) {
    let sommaCoperturaVideoPerc = 0
    let sommaCoperturaAllegatiPerc = 0
    
    const studentsT = Object.keys(contest2024trace[item][timelineIds[i]])
    for (let j = 0; j < studentsT.length; j++) {
      const trace = contest2024trace[item][timelineIds[i]][studentsT[j]][0]; // la sessione è la prima...
      
      console.log(`TOV: TRACE timeline${item} student: ${studentsT[j]}:`, trace)
      const traceOverview = analyzeTrace(trace) as any;
      console.log(`TOV: timeline${item} student: ${studentsT[j]}:`, traceOverview)
      if (!tracesOverview[timelineIds[i]])
      {
        tracesOverview[timelineIds[i]] = {"score" : {}, "students" : []}
      }
      tracesOverview[timelineIds[i]]["students"][studentsT[j]] = traceOverview
      sommaCoperturaVideoPerc+=traceOverview["coperturaVideo"]["coperturaVideoPercentuale"]
      sommaCoperturaAllegatiPerc+=traceOverview["percentualeAllegatiAperti"]
    }
    tracesOverview[timelineIds[i]]["score"]= {
      //n.b la copertura media di video e allegati tiene conto anche di studenti iscritti al contest, ma
      // che ancora non hanno partecipato
      "coperturaMediaVideoPerc" : sommaCoperturaVideoPerc/rolledStudents.length,
      "coperturaMediaAllegatiPerc" : sommaCoperturaAllegatiPerc/rolledStudents.length
    }
  }
  // Carico i dati delle timeline 
  const timelines = yield all(
    timelineIds.map(function* (timelineId) {
      const timelineInfo = (yield call(willGetPublicExperiment, { payload: timelineId }) as any) as any;
      return {
        "id": timelineId, "catalogExperiment": timelineInfo["catalogExperiment"],
        "title": timelineInfo["title"]
      }
    })
  )
  console.log("TLR:Timelines:", timelines)
  // Carico nello store i dati degli studenti relativi alla classe partecipante
  yield all(students.map((student: any) => call(willGetUser, { payload: student }))
  );

  
  //console.log("SAGA CONTEST: esamino classroom:", classroom)
  // 
  const schoolData = yield call(willGetUserProfileAsDirector, { payload: teacherId })
  console.log("SAGA CONTEST: schoolData:", schoolData)
  const userData = yield call(willGetUser, { payload: teacherId })
  console.log("SAGA CONTEST: userData:", userData)
  const itemResult = {
    "id": `${teacherId}_${classroom}`,
    "school": schoolData["school"],
    teacherId,
    classroom,
    "tot_partecipants": totStudents,
    "tot_rolled_partecipants" : rolledStudents.length,
    "tot_timelines": totTimelines,
    "timelines": timelines,
    "given_name": userData["given_name"],
    "family_name": userData["family_name"],
    "full_name": `${userData["family_name"]} ${userData["given_name"]}`,
    "email": userData["email"],
    "tracesOverview" : tracesOverview,
    "score" : calcolaPunteggioFinale(tracesOverview)
  }
  console.log("SAGA CONTEST: esamino itemResult:", itemResult)
  return itemResult;
}

function* buildContest2024SchoolDataOld(item: any, contest2024trace: any): any {
  const partecipants = Object.keys(contest2024trace[item])
  const totPartecipants = partecipants.length;

  // Carico nello store i dati degli studenti
  yield all(partecipants.map((student: any) => call(willGetUser, { payload: student }))
  );

  // d3deab88-60dd-4e8d-8cec-1f13e2dba31b_4fsa
  const [teacherId, classroom] = item.split("_")
  console.log("SAGA CONTEST: esamino teacherId:", teacherId)

  //console.log("SAGA CONTEST: esamino classroom:", classroom)
  // 
  const schoolData = yield call(willGetUserProfileAsDirector, { payload: teacherId })
  console.log("SAGA CONTEST: schoolData:", schoolData)
  const userData = yield call(willGetUser, { payload: teacherId })
  console.log("SAGA CONTEST: userData:", userData)
  const itemResult = {
    "id": `${teacherId}_${classroom}`,
    "school": schoolData["school"],
    teacherId,
    classroom,
    "tot_partecipants": totPartecipants,
    "given_name": userData["given_name"],
    "family_name": userData["family_name"],
    "full_name": `${userData["family_name"]} ${userData["given_name"]}`,
    "email": userData["email"]
  }
  console.log("SAGA CONTEST: esamino itemResult:", itemResult)
  return itemResult;
}

function* willBuildContest2024Schools(action: any): any {
  const contest2024trace = action.payload
  const items = Object.keys(contest2024trace); // contest2024  const passcodes = Object.keys(contest2024Schema.current)

  // Eseguiamo le chiamate asincrone per ciascun item

  const updatedItems = yield all(
    items.map((item: any) => call(buildContest2024SchoolData, item, contest2024trace))
  );
  console.log("SAGA CONTEST: item aggiornati:", updatedItems)
  yield put(PassCodeUserActions.setContest2024Schools(updatedItems))
}

//@audit LOGOUT WITH PASSCODE
function* willLogoutWithPassCode(action: any): any {
  //console.log('in willLogoutWithPassCode function with ', action);
  let sessionTrace = yield (JSON.parse(localStorage.getItem("RialeSessionTrace") as any))
  //console.log("DBA:willLogout SessionTrace:", sessionTrace);

  let error = null;
  if (sessionTrace != null) {
    // aggiungo la data di fine
    const stopDate = moment.now();
    sessionTrace["stopDate"] = stopDate;
    sessionTrace["formattedStopDatetime"] = moment(stopDate).format("YYYY/MM/DD - HH:mm:ss");

    // la sessione viene compressa prima di essere convertita in json
    const sessionData = JSON.stringify(compress(sessionTrace))

    const group = `${configuration[stage]["analyticsSessionGroup"]}` // "TEST_BETA_NOAUTH_01";
    const passcode = action.payload["passCode"];
    const createAnaliticsSessionUrl = `${configuration[stage]["createAnaliticsSessionEndpoint"]}`;
    const contentBody = JSON.stringify({ group: group, passcode: passcode, content: sessionData })
    //console.log("Content body", contentBody);

    //https://stackoverflow.com/questions/29775797/fetch-post-json-data
    try {
      const response = yield call(() =>
        fetch(createAnaliticsSessionUrl, {
          method: 'POST', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: contentBody
        })
          .then(response => response.json())
          .then(myJson => myJson).catch(e => {
            //console.log("DBA:Eccezione nel salvataggio:", e);
            error = e;
          })
      );
      //console.log("DBA:In willLogoutWithPassCode (response)-->", response);
      if (response["errorType"] != null) {
        error = response["errorMessage"]
        //console.log("DBA:willLogoutWithPassCode error", error);
      }
    } catch (e) {
      error = e;
      //console.log("DBA:willLogoutWithPassCode error", e);
    }
  }
  else {
    error = "Session Trace is null!";
  }
  // consento il logout solo in caso non ci sia un errore nel salvataggio della sessione
  if (error == null) {
    //console.log("DBA: Nessun errore: procedo con la rimozione del trace e il logout");
    localStorage.removeItem("RialeSessionTrace");
    yield put(PassCodeUserActions.didLogoutWithPassCode())

    //@audit TO BE FIXED La chiamata seguente sembra  non funzionare
    //yield logoutFromRialenet()
  }
  else {
    //console.log("DBA: Impossibile procedere al salvataggio della sessione:",error)
    yield put(PassCodeUserActions.didSessionSavingError(error));
  }
}

// Saga che salva nel backend eventuali sessioni del Contest 2024
// presenti nel local storage e quindi non ancora caricate (ogni )
function* saveAllStudentsSessionsFromLocalStorage() {
  const DELAY_IN_SECONDS = 10
  while (true) {
    // Esegui l'operazione
    //console.log("TLW: richiamo saveAllStudentsSessionsFromLocalStorage...");
    yield call(willSaveContest2024StudentSessions, { "payload": { "userId": null } });
    // Aspetta X secondi prima di rieseguire l'operazione (modifica 60000 per 60 secondi)
    yield delay(DELAY_IN_SECONDS * 1000);
  }
}

function* willSaveContest2024StudentSessions(action: any): any {

  const PREFIX = "contest2024trace__"
  // se viene specificato lo userId salvo solo le sue sessioni,
  // altrimenti salvo tutte quelle che trovo nel localStorage
  const userId = action.payload?.userId;
  const sessionsToSave = Object.keys(localStorage).filter((key: any) => {
    return key.startsWith(PREFIX) && (userId == null || key.endsWith(userId))
  })
  //console.log("TLW: sessionsToSave:", sessionsToSave)
  yield all(sessionsToSave.map((key: any) => call(willSaveContest2024Session,
    { payload: { "contest2024storageTrace": key, "passCode": key } })))
}

function* willSaveContest2024Session(action: any): any {
  //console.log('in willLogoutWithPassCode function with ', action);
  if (action.payload["contest2024storageTrace"] == null) {
    console.log("TLW: contest2024storageTrace null from saga.")
    return;
  }

  let sessionTrace = yield (JSON.parse(localStorage.getItem(action.payload["contest2024storageTrace"]) as any))
  //console.log("DBA:willLogout SessionTrace:", sessionTrace);
  let error = null;
  if (sessionTrace != null) {
    // aggiungo la data di fine
    const stopDate = moment.now();
    sessionTrace["stopDate"] = stopDate;
    sessionTrace["formattedStopDatetime"] = moment(stopDate).format("YYYY/MM/DD - HH:mm:ss");
    const isVoidSession = sessionTrace["actions"] == null || sessionTrace["actions"].length == 0;

    // la sessione viene compressa prima di essere convertita in json
    const sessionData = JSON.stringify(compress(sessionTrace))

    const group = `${configuration[stage]["contest2024Group"]}` // "TEST_BETA_NOAUTH_01";
    const ulid = sessionTrace["id"];
    const passcode = action.payload["passCode"];
    const createAnaliticsSessionUrl = `${configuration[stage]["createAnaliticsSessionEndpoint"]}`;
    const contentBody = JSON.stringify({ group: group, ulid: ulid, passcode: passcode, content: sessionData })
    //console.log("Content body", contentBody);

    //https://stackoverflow.com/questions/29775797/fetch-post-json-data
    if (isVoidSession) {
      console.log("TLW: saga: nella traccia l'array delle azioni è vuoto, non salvo nel db");
    }
    else
      try {
        const response = yield call(() =>
          fetch(createAnaliticsSessionUrl, {
            method: 'POST', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: contentBody
          })
            .then(response => response.json())
            .then(myJson => myJson).catch(e => {
              console.log("TLW: Eccezione nel salvataggio:", e);
              error = e;
            })
        );
        console.log("TLW: willSaveContest2024 (response)-->", response);
        if (response["errorType"] != null) {
          error = response["errorMessage"]
          console.log("TLW :willSaveContest2024 session error", error);
        }
      } catch (e) {
        error = e;
        console.log("TLW :willSaveContest2024 session error", e);
      }
  }
  else {
    error = "Contest 2024 Session Trace is null!";
  }
  // consento il logout solo in caso non ci sia un errore nel salvataggio della sessione

  if (error == null) {
    console.log("TLW: Nessun errore: procedo con la rimozione del trace di Contest 2024:", action.payload["contest2024storageTrace"]);
    localStorage.removeItem(action.payload["contest2024storageTrace"]);
  }
  else {
    console.log("TLW: Impossibile procedere al salvataggio della sessione del contest 2024:", error)
  }
}


function* logoutFromRialenet(): any {
  //console.log("DBA RIALENET:Tentativo di logout:");
  const url = "https://rialenet-forum.herokuapp.com/logout";
  const response = yield call(() =>
    fetch(url, {
      method: 'GET', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(myJson => myJson).catch(e => {
        //console.log("DBA RIALENET:Eccezione nel logout:", e);
      })
  );
}

/*
  function* willLogoutWithPassCodeFromAuthUser(action:any) {
    //console.log('in willLogoutWithPassCode function with ', action);
    const group = "TEST_BETA_01"

    try {
      let response = yield call(
        AnalyticsSessionsApi.createAnalyticsSession,
        group,
        action.payload["passCode"],
        sessionData
      );
      //console.log("createAnalyticsSession result", response);

    } catch (e) {
      //console.log("Amplify createAnalyticsSession error", e);
    }

    yield put(PassCodeUserActions.didLogoutWithPassCode())
  }
  */
